import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBCJSCqRiKX9kNpEQ43mG-73uE3Pr81vuE",
  authDomain: "jawd-authentication.firebaseapp.com",
  projectId: "jawd-authentication",
  storageBucket: "jawd-authentication.appspot.com",
  messagingSenderId: "447462891356",
  appId: "1:447462891356:web:6fee37a8f4af693e4eeb99",
  measurementId: "G-6TEJEKLJRJ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Function to handle Google login
const handleGoogleLogin = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result.user.uid);
    })
    .catch((error) => {
      console.error(error);
    });
};

const handleGoogleLogout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful
      console.log("Google Sign-out successful");
    })
    .catch((error) => {
      // An error happened
      console.error("Google Sign-out error:", error);
    });
};


export { auth, handleGoogleLogin, handleGoogleLogout };
