import React, { useState, useEffect, useMemo, useTransition, lazy, Suspense, useCallback, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import "./Product.css";
import Navbar from "../../component/Navbar/Navbar";
import Helmet from "react-helmet";
import { getSeries, productFetchAPI } from "../../services/Services";
import SearchBar from "../../component/Search-and-Item/SearchBar";
import TruncatedText from "../../component/TruncatedText/TruncatedText";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import TopProductMobile from "../../component/TopProductMobile/TopProductMobile";
import NavTop from "../../component/NavTop/NavTop";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { preloadImages } from "../../component/PreloadImages/PreloadImages";
import Loader from "../../component/Loader/Loader";
import { FaStar } from 'react-icons/fa';

const Footer = lazy(() => import("../../component/Footer/Footer"));

const Product = () => {
  const location = useLocation();
  const NoProduct = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/no_products_found.png?updatedAt=1723459507627?tr-f_auto,q_auto";
  const { type, category, series } = useParams();
  const [isPending, startTransition] = useTransition();
  const [products, setProducts] = useState([]);
  const [getSeriesDet, setGetSeriesDet] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [wishlist, setWishlist] = useState(() => JSON.parse(localStorage.getItem("wishlist")) || []);
  const [showTopComponents, setShowTopComponents] = useState(true);
  const [renderRestOfPage, setRenderRestOfPage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  //Category images object
  const categoryImages = [
    { category: 'chinos', imageLink: 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/chinos_products_banner.png?tr=f-auto,q_auto' },
    { category: 'tshirt_regular_halfsleeve', imageLink: 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/tshirt_products_banner.png?updatedAt=1727286913692?tr=f-auto,q_auto' },
    { category: 'tshirts', imageLink: 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/tshirts_products_banner.png?tr=f-auto,q_auto' },
    { category: 'default', imageLink: 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/tshirts_products_banner.png?tr=f-auto,q_auto' }
  ];
const defaultImageLink = 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/chinos_products_banner.png?tr=f-auto,q_auto';
const matchedCategory = categoryImages.find(item => item.category === category);
const imageToShow = matchedCategory ? matchedCategory.imageLink : defaultImageLink;

  const bannerRef = useRef(null);

  useEffect(() => {

    const handlePageLoad = () => {
      setPageLoading(false); // Page fully loaded, hide the loader
    };

    if (document.readyState === 'complete') {
      handlePageLoad(); // If already loaded
    } else {
      window.addEventListener('load', handlePageLoad); // Wait for full load
    }
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const res = await productFetchAPI(type, category, series);
        startTransition(() => {
          setProducts(res.data || []);
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();

    return () => {
      setProducts([]);
      setIsLoading(false);
    };
  }, [type, category, series]);

  useEffect(() => {
    const fetchSeries = async () => {
      try {
        const res = await getSeries();
        startTransition(() => {
          setGetSeriesDet(res.data[0].shirtSeries);
        });
      } catch (err) {
        console.error(err);
      }
    };

    fetchSeries();

    return () => setGetSeriesDet([]);
  }, []);

  const filteredProducts = useMemo(() => {
    return searchTerm
      ? products.filter((product) =>
        product.productName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.discountedPrice.toString().includes(searchTerm)
      )
      : products;
  }, [searchTerm, products]);

  const handleTypeChange = useCallback(
    (newType) => startTransition(() => setSearchTerm(newType)),
    []
  );

  const handleWishlistToggle = useCallback(
    (productId) => {
      startTransition(() => {
        setWishlist((prevWishlist) => {
          const updatedWishlist = prevWishlist.includes(productId)
            ? prevWishlist.filter((id) => id !== productId)
            : [...prevWishlist, productId];
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
          return updatedWishlist;
        });
      });
    },
    []
  );

  const productsToRender = searchTerm ? filteredProducts : products;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      startTransition(() => {
        setShowTopComponents(scrollTop <= window.innerHeight * 0.1);
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      startTransition(() => setIsMobileView(window.innerWidth < 769));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    preloadImages(products.map((product) => product.imageUrl));
  }, [products]);

  const isMatchingPath = location.pathname === "/products/men/printed_cap" || location.pathname === "/products/men/tshirt_regular_halfsleeve/printed";

  const metaTitle = `${category.replace(/_/g, ' ')} for ${type} of ${series} series - Buy Now | JAWD India`;
  const metaDescription = `Buy the best ${category} of ${series} series for ${type} offered by JAWD at the most affordable prices, with additional discounts.`;
  const metaKeywords = `buy ${category}, products for ${type}, series of ${series}`;

  if (pageLoading) {
    return <Loader />;
  }
  const BannerMarginCal = location.pathname === '/products/men/printed_cap' || location.pathname === '/products/men/tshirt_regular_halfsleeve/all';

  return (
    <>
      <div className="products-section">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          {products.map((product) => (
            <link key={product.imageUrl} rel="preload" as="image" href={product.imageUrl} />
          ))}
        </Helmet>
        <NavTop />
        <Navbar />
        <div className="top-product-mobile">
          <TopProductMobile />
        </div>
        <div className="search-bar-desktop">
          <SearchBar series={getSeriesDet} onTypeChange={handleTypeChange} />
        </div>
        <div className="search-bar-mobile">
          {showTopComponents && <SearchBar series={getSeriesDet} onTypeChange={handleTypeChange} />}
        </div>
        <div
          ref={bannerRef}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%" // Full screen width
          }}
          className='banner-content'
        >
          <div className={BannerMarginCal ? 'banner-top' : 'banner-top-without-series'} style={{ width: "100%", backgroundColor: "#f0f0f0" }}>
            <img
              style={{
                width: "100%",         // Full width of the div
                height: "auto",        // Adjust height automatically to maintain aspect ratio
                objectFit: "contain"   // Ensures the image maintains its aspect ratio
              }}
              src={imageToShow}
              alt="Banner" // Always include an alt attribute for images
            />
          </div>
        </div>


        <div className="product-holder-holder">
          {!isLoading ? (
            <div
              style={{
                marginTop: isMobileView ? (isMatchingPath ? "5vh" : "3vh") : "0vh",
              }}
              className="product-holder"
            >
              {products.length > 0 ? (
                productsToRender.map((product) => (
                  <div className="product" key={product._id}>
                    <Link to={`/product/${product._id}`}>
                      <div className="product-img-wrapper">
                        <div className="product-img">
                          <LazyLoadImage
                            src={product.imageUrl.replace("/upload/", "/upload/f_auto,q_auto/")}
                            alt={product.productName}
                            width="206"   // Ensure consistent width
                            height="270"  // Ensure consistent height
                            effect="blur" // Optional: Adding a blur effect until the image loads
                          />
                          <div
                            className="add-to-cart"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleWishlistToggle(product._id);
                            }}
                          >
                            {wishlist.includes(product._id) ? (
                              <AiFillHeart style={{ color: "#f76363", fontSize: "15px", cursor: "pointer" }} />
                            ) : (
                              <AiOutlineHeart style={{ color: "#000", fontSize: "15px", cursor: "pointer" }} />
                            )}
                          </div>
                          <div
                            className="rating"
                          >
                            <FaStar style={{ color: 'gold', fontSize: '10px' }} />
                            <p>{product.productShortDescription}</p>
                          </div>
                        </div>

                      </div>
                    </Link>
                    <div className="product-content">
                      <div className="prod-description">
                        {/* <p className="brand">Brand Special</p> */}
                        <h3 className="prod-name">
                          <Link className="prod-name" to={`/product/${product._id}`}>
                            {/* <TruncatedText text={product.productName} /> */}
                            {product.productName}
                          </Link>
                        </h3>
                        <p style={{ fontSize: "11px", color: "#b8b8b8", marginTop: "-10px" }}>Brand Special</p>
                        <div className="pricing">
                          <h3 className="discounted-price">₹{product.discountedPrice}</h3>
                          <div className="original-price-discount">
                            <h4>
                              <del>₹{product.originalPrice}</del>
                              <span style={{ color: "#5ee802" }}>({Math.round(
                                ((product.originalPrice - product.discountedPrice) /
                                  product.originalPrice) *
                                100
                              )}
                                % Off)
                              </span>
                            </h4>
                          </div>
                        </div>
                        {/* {!product.category.includes("outfit") && (
                            <div className="available-colors-for-prod">
                              <p className="color-txt">Available Colors:</p>
                              <div className="color-boxes">
                                {product.availableColor.map((color) => (
                                  <div key={color.code} className="color-box" style={{ backgroundColor: color.code }}></div>
                                ))}
                              </div>
                            </div>
                          )} */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-products-found">
                  <div className="no-product-content">
                    <img src={NoProduct} alt="No products found" />
                    <p>
                      Hmm... we're working on this. The products will be available soon.
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="product-holder">
              {Array.from({ length: 8 }).map((_, index) => (
                <div className="product" key={index}>
                  <div className="product-img-animate skull-animation"></div>
                  <div className="product-content">
                    <div className="prod-description">
                      <p className="brand skull-animation"></p>
                      <h3 className="prod-name skull-animation"></h3>
                      <div className="pricing skull-animation"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Suspense fallback={<div className="footer-loader"></div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
};

export default Product;


