import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaHome, FaShoppingCart, FaHeart } from 'react-icons/fa';
import { AiOutlineShoppingCart, AiOutlineHeart } from 'react-icons/ai';
import { AiOutlineOrderedList } from 'react-icons/ai';
import { GetCartedItems } from "../../services/Services";
import './Navbar.css';


const Navbar = () => {
  const Logo = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/jawd_transparent.png?updatedAt=1723459507629?tr=f-auto,q_auto"
  const MenProduct = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/men_product.jpg?updatedAt=1723459507647?tr=f-auto,q_auto"
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [productCount, setProductCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('cartItems')) || [];
    setProductCount(storedProducts.length);
  }, [JSON.parse(localStorage.getItem("cartItems"))]);


  useEffect(() => {
    localStorage.setItem('currentPath', location.pathname);
  }, [location.pathname]);

  const currentPath = location.pathname;
  return (
    <div>
      <div className={`${isHome ? 'navbar with-top' : 'navbar without-top'}`}>
        <div className="navbar-stripe">
          <div className="free-ship">
            <p>Choose.Avail.Purchase.Repeat.Enjoy FREE Shipping for any order.</p>
          </div>
          <div className="try-offer">
            <p>Try JAWD24 To Get Flat 5% OFF On Any Deal.</p>
          </div>
        </div>
        <div className="navbar-links">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="list">
            <ul>
              <li className="menu-item">
                <Link to="/">
                  <a href="#">Home</a>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/products/men/all/all">
                  <a href="#">Men</a>
                </Link>
                <div className="submenu-holder">
                  <div className="submenu">
                    <div className="submenu-menu" style={{ width: "30vw" }}>
                      <div>
                        <h4>Topwears</h4>
                        <Link to="/products/men/tshirt_solid_halfsleeve/solid">
                          <p>Solid Tshirt</p>
                        </Link>
                        <Link to="/products/men/tshirt_regular_halfsleeve/all">
                          <p>Printed Tshirt</p>
                        </Link>
                        <Link to="/products/men/casual_college_outfit/all">
                          <p>College Outfit Combo</p>
                        </Link>
                      </div>
                      <div className="submenu-menu" style={{ marginLeft: "7vw", width: "25vw" }}>
                        <div>
                          <h4>Bottomwears</h4>
                          <Link to="/products/men/joggers/all">
                            <p>Joggers</p>
                          </Link>
                          <Link to="/products/men/cargo/all">
                            <p>6 Pockets Cargo</p>
                          </Link>
                          <Link to="/products/men/chinos/all">
                            <p>Chinos</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="submenu-menu" >
                      <div>
                        <h4>Combos</h4>
                        <Link className='prod-link' to="/products/men/combo/joggers" >
                          <p>Joggers Combos</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/combo/chinos" >
                          <p>Chinos Combos</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/casual_college_outfit/all" >
                          <p>College Outfit Combo</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/pick_any_two/joggers" >
                          <p>Pick Any Two - Joggers</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/pick_any_two/chinos" >
                          <p>Pick Any Two - Chinos</p>
                        </Link>
                      </div>
                    </div>
                    <div className="submenu-menu">
                      <div>
                        <h4>Themes</h4>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/all" >
                          <p>All</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/Sporty" >
                          <p>Sporty</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/city" >
                          <p>City</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/filmy" >
                          <p>Filmy</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/iconic_fiction" >
                          <p>Iconic Fiction</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/ranodm" >
                          <p>Random</p>
                        </Link>
                      </div>
                      <div style={{ marginTop: "37px" }}>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/Voyager" >
                          <p>Voyager</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/calligraphy" >
                          <p>Calligraphy</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/iconic_character" >
                          <p>Iconic Character</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/japanese_caligraphy" >
                          <p>Japanese Calligraphy</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/motivational" >
                          <p>Motivational</p>
                        </Link>
                        <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/rider" >
                          <p>Rider</p>
                        </Link>
                      </div>
                    </div>
                    <div className="submenu-menu">
                      <div className="submenu-img-holder">
                        <img src={MenProduct} alt="men's-product-banner" />
                      </div>

                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-item" style={{ cursor: "not-allowed" }}>
                <Link style={{ cursor: "not-allowed" }}>
                  <a style={{ cursor: "not-allowed" }} href="#" className="women-link">
                    Women
                    <span className="coming-soon-box">Coming soon</span>
                  </a>
                </Link>
                {/* Commented out because of coming soon, will add when products are available */}
                {/* <div className="submenu-holder">
                  <div className="submenu">
                    <div className="submenu-menu">
                      <h4>Topwears</h4>
                      <Link to="/products/women/tshirt_regular_halfsleeve/printed">
                        <p>Printed T-shirts</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve_oversized/printed">
                        <p>Oversized T-shirts</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/solid">
                        <p>Half Sleeve T-shirts</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/combo">
                        <p>Printed Tshirt Combo</p>
                      </Link>
                    </div>
                    <div className="submenu-menu">
                      <h4>Bottomwears</h4>
                      <Link to="/products/women/joggers/all">
                        <p>Joggers</p>
                      </Link>
                      <Link to="/products/women/six_pocket_cargo/all">
                        <p>6 Pockets Cargo</p>
                      </Link>
                      <Link to="/products/women/chinos/all">
                        <p>Chinos</p>
                      </Link>
                      <Link to="/products/women/bottomwear/combo">
                        <p>Bottom Wear Combo</p>
                      </Link>
                    </div>
                    <div className="submenu-menu">
                      <h4>Themes</h4>
                      <Link to="/products/women/tshirt_regular_halfsleeve/minimal">
                        <p>Minimal</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/jawd_special">
                        <p>JAWD Special</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/party_mood">
                        <p>Party Mood</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/voyager">
                        <p>Traveller Special</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/magical">
                        <p>Magical</p>
                      </Link>
                      <Link to="/products/women/tshirt_regular_halfsleeve/cute_cartoon">
                        <p>Illustrations</p>
                      </Link>
                    </div>
                    <div className="submenu-menu">
                      <div className="submenu-img-holder">
                        <img src={MenProduct} />
                      </div>

                    </div>
                  </div>
                </div> */}
              </li>
              <li className="menu-item">
                <Link to="/products/all">
                  <Link to="/products/men/printed_cap/all">
                    <a href="#">Accessories</a>
                  </Link>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/about">
                  <a href="#">About</a>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/products/all/combo/all">
                  <a href="#">Combos</a>
                </Link>
              </li>
              <div className="menu-item-multi-icons">
                <div className="menu-item-icons">
                  <Link to="/exchange">
                    <AiOutlineOrderedList size={20} style={{ marginRight: "5px" }} />
                  </Link>
                  <Link to="/cart">
                    <div className="cart-icon-container">
                      <AiOutlineShoppingCart size={22} style={{ marginRight: "5px" }} />
                      {productCount > 0 && (
                        <div className="product-count">
                          {productCount}
                        </div>
                      )}
                    </div>
                  </Link>
                  <Link to="/wishlist">
                    <AiOutlineHeart size={22} style={{ marginRight: "5px" }} />
                  </Link>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="navbar-mobile">
        <div className="mobile-links">
          <Link to="/" style={{ color: currentPath === '/' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <FaHome title="Home" size={23} color={currentPath === '/' ? '#000' : '#7d7d7d'} /> {/* Home icon */}
          </Link>
          <Link to="/" style={{ color: currentPath === '/' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <p style={{ color: currentPath === '/' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>HOME</p>
          </Link>
        </div>

        <div className="mobile-links">
          <Link to="/cart" style={{ color: currentPath === '/cart' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <div className="cart-icon-container">
              <FaShoppingCart title="cart" size={23} color={currentPath === '/cart' ? '#000' : '#7d7d7d'} /> {/* Cart icon */}
              {productCount > 0 && (
                <div className="product-count-mobile">
                  {productCount}
                </div>
              )}
            </div>
          </Link>
          <Link to="/cart" style={{ color: currentPath === '/cart' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <p style={{ color: currentPath === '/cart' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>CART</p>
          </Link>
        </div>

        <div className="mobile-links">
          <Link to="/wishlist" style={{ color: currentPath === '/wishlist' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <FaHeart title="wishlist" size={23} color={currentPath === '/wishlist' ? '#000' : '#7d7d7d'} />
          </Link>
          <Link to="/wishlist" style={{ color: currentPath === '/wishlist' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <p style={{ color: currentPath === '/wishlist' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>WISHLIST</p>
          </Link>
        </div>

        <div className="mobile-links">
          <Link to="/exchange" style={{ color: currentPath === '/exchange' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <AiOutlineOrderedList title="My orders" size={23} color={currentPath === '/exchange' ? '#000' : '#7d7d7d'} /> {/* Exchange icon */}
          </Link>
          <Link to="/exchange" style={{ color: currentPath === '/exchange' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>
            <p style={{ color: currentPath === '/exchange' ? '#000' : '#7d7d7d', textDecoration: 'none' }}>MY ORDERS</p>
          </Link>
        </div>
      </div>

    </div>
  );
};

export default Navbar;
