import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './TopProductMobile.css';

const TopProductMobile = () => {
    const TshirtUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/tshirt_prod_img.jpg?updatedAt=1723356686132?tr=f-auto,q_auto";
    const JoggersUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/joggers_prod_img.jpg?updatedAt=1723356679551?tr=f-auto,q_auto";
    const CapsUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/caps_prod_img.jpg?updatedAt=1723356673609?tr=f-auto,q_auto";
    const CargoUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/polo_tshirt_2.png?updatedAt=1723356684952?tr=f-auto,q_auto";
    const ComboUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/shorts_img_2.png?updatedAt=1723356685660?tr=f-auto,q_auto";
    const ChinosUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/chinos_img_2.png?updatedAt=1723356676069?tr=f-auto,q_auto"
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const isCartPage = location.pathname === "/cart";
    return (
        <div className={isHomePage || isCartPage ? 'top-product-mobile-holder-home' : 'top-product-mobile-holder'} style={isHomePage ? { marginTop: '7vh' } : {}}>
            <div className='top-product-mobile-holder-holder'>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/tshirt_regular_halfsleeve/all"><img src={TshirtUrl} alt='regular-printed-tshirt' width="60" height="80"/></Link>
                    </div>
                    <p>Tshirt</p>
                </div>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/joggers/all"><img src={JoggersUrl} alt='joggers-img' width="60" height="76"/></Link>
                    </div>
                    <p>Joggers</p>
                </div>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/printed_cap/all"><img src={CapsUrl} alt='caps-img' width="60" height="75"/></Link>
                    </div>
                    <p>Caps</p>
                </div>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/cargo/all"><img src={CargoUrl} alt='cargo-img' width="60" height="60"/></Link>
                    </div>
                    <p>Cargo</p>
                </div>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/combo/all"><img src={ComboUrl} alt='combo-img' width="60" height="60"/></Link>
                    </div>
                    <p>Combos</p>
                </div>
                <div className='top-product-mobile-list'>
                    <div className='top-product-mobile-list-item-img'>
                        <Link to="/products/men/chinos/all"><img src={ChinosUrl} alt='chinos-img' width="60" height="60"/></Link>
                    </div>
                    <p>Chinos</p>
                </div>
            </div>
        </div>
    )
}

export default TopProductMobile