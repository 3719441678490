import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../component/Navbar/Navbar'
import NavTop from '../../component/NavTop/NavTop'
import Footer from '../../component/Footer/Footer'

const ExchangePolicy = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
            <Navbar />
            <NavTop />
            <div className='about-us-content-holder'>
                <div className='about-us-content'>
                    <div className='abt-hd-1'>
                        <h1>Exchange/Return Policy</h1>
                    </div>
                    <div className='abt-psg-1'>
                        <ol>
                            <li><p>The return & exchange can be done within 5 days of the delivery, for example, if the customer gets the delivery on 1st July 2024, the request for return or exchange can only be done before 23:59:59 HRS of 6th July, 2024
                            </p></li>
                            <li><p>The request to return or exchange can be done very easily from <Link to="/exchange">https://wearejawd.in/exchange</Link>. After clicking the link, a page will be opened where all your orders from past 5 days will be listed. You only have to select the product from the list (multiple can be selected at one time) and then click on the “Request to Exchange” button on top left and that’s it. 
                            </p></li>
                            <li><p>After the action described in point 2, you will get an email in your registered mail, and our customer executive will call you within 24-48 Hrs (Excluding Weekends, National Holidays, Regional Holidays) with the details.
                            </p></li>
                            <li><p>They will hear about your problem in person and try to understand the exact reason of returning or exchanging.
                            </p></li>
                            <li><p>If your concern is damaged product, then you will be provided a WhatsApp number where you have to send the picture of the product from front, and back, and from both the side. <b>NOTE: We cannot process your exchange or return request, if the tag of the product is torn or damaged.</b>
                            </p></li>
                            <li><p>In case of genuinely damaged product, you can opt for exchange the product with a product within similar price range or opt for refund and we will process the refund within 7-10 working days, in this case you have to provide our executive your payment details, like UPI ID, or the Phone Number Associated with UPI ID, or the Bank Account details which is limited to Account Number, IFS Code only. If anybody tells you to share other sensitive information like Credit/Debit Card Number, CVV or Password or anything except mentioned above, never ever share that. Please be informed that JAWD will never ask for any sensitive details from our customers in any scenario. 
                            </p></li>
                            <li><p>If any other issue happens, like the size or colour doesn’t match then you can opt for exchange or refund. If you opt for exchanging you can buy any product within similar price range. In case of refund, we will refund your money within 7-10 working days after we receive the product from your side.
                            </p></li>
                            <li><p>Please courier the product to the given address of our primary warehouse <b>Barabahera, Kanaipur, VTC, Konnogar, P.O Barabahera, Sub dist. Serampore, Uttarpara, Dist. Hooghly, West Bengal, PIN. 712246</b>. Please note in case of refund we do not refund your courier charges to return the product to us. In case of exchanging we will adjust the courier charges.</p></li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ExchangePolicy