import React, { useEffect, useState } from 'react';
import Navbar from '../../component/Navbar/Navbar';
import { Link, useLocation } from 'react-router-dom';
import TopProductMobile from '../../component/TopProductMobile/TopProductMobile';
import './WishList.css';
import { GetCartedItems } from '../../services/Services';
import { FaHeart } from 'react-icons/fa';
import Footer from '../../component/Footer/Footer';
import TruncatedText from '../../component/TruncatedText/TruncatedText';
import NavTop from '../../component/NavTop/NavTop';

const WishList = () => {
    const EmptyWishList = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/empty_wishlist.gif?updatedAt=1723459508117?tr=f-auto,q_auto"
    const location = useLocation();
    const isWishPage = location.pathname === "/wishlist";
    const [wishListItems, setWishListItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const products = JSON.parse(localStorage.getItem('wishlist')) || [];
        const dataArr = { productIds: products };
        GetCartedItems(dataArr)
            .then((res) => {
                console.log(res);
                setWishListItems(res);
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
        console.log(products);
    }, []);

    const handleWishlistToggle = (productId) => {
        let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        if (wishlist.includes(productId)) {
            wishlist = wishlist.filter(id => id !== productId);
        } else {
            wishlist.push(productId);
        }
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
    };

useEffect(()=>{
    window.scrollTo(0,0);
},[])

    return (
        <div className="products-section">
            <Navbar />
            <NavTop />
            <div className='top-product-mobile'>
                <TopProductMobile />
            </div>
            <div className="product-holder-holder">
                {!isLoading ? (
                    wishListItems.length !== 0 ? (
                        <div className={!isWishPage ? "product-holder" : "product-holder-wishlist"}>
                            {wishListItems.map((product, index) => (
                                <div className="product" key={index}>
                                    <Link to={`/product/${product.id}`}>
                                        <div className="product-img">
                                            <img
                                                src={product.colorImageUrl.replace('/upload/', '/upload/f_auto,q_auto/')}
                                                alt={product.productName}
                                            />
                                        </div>
                                    </Link>
                                    <div className="product-content">
                                        <div className="prod-description">
                                            <p className="brand">Brand Special</p>
                                            <h3 className="prod-name">
                                                <Link className="prod-name" to={`/product/${product.id}`}>
                                                    <TruncatedText text={product.productName} />
                                                </Link>
                                            </h3>
                                            <div className="pricing">
                                                <h3 className="discounted-price">
                                                    ₹{product.discountedPrice}
                                                </h3>
                                                <div className="original-price-discount">
                                                    <h4>
                                                        <del>₹{product.originalPrice}</del>({Math.round(((product.originalPrice - product.discountedPrice) / product.originalPrice) * 100)}% Off)
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleWishlistToggle(product._id)}
                                            className="add-to-cart"
                                        >
                                            <FaHeart style={{ color: '#f76363', fontSize: '15px', cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='empty-wishlist'>
                            <div className='empty-wishlist-content'>
                                <img src={EmptyWishList} />
                                <p>Oops! Your wishlist looks empty. Try adding some products.</p>
                            </div>
                        </div>
                    )
                ) : (
                    <div style={{marginTop: "0vh"}} className="product-holder">
                        {Array.from({ length: 8 }, (_, index) => (
                            <div className="product" key={index}>
                                <div className="product-img-animate skull-animation"></div>
                                <div className="product-content">
                                    <div className="prod-description">
                                        <p className="brand-animate skull-animation"></p>
                                        <h3 className="prod-name-animate skull-animation"></h3>
                                        <div className="pricing">
                                            <h3 className="discounted-price-animate skull-animation"></h3>
                                        </div>
                                        <div className="available-colors-for-prod-animate skull-animation">
                                            <div className="color-boxes">
                                                <div className="color-box-animate skull-animation"></div>
                                                <div className="color-box-animate skull-animation"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default WishList;
