import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const FaqArray = [
    {
      question: "What is the delivery process?",
      answer:
        "Our delivery process begins as soon as your order is placed. We aim to dispatch orders within 1-2 business days. Once your order is dispatched, you will receive a shipping confirmation email with tracking information. Orders are typically delivered within [X to Y] business days, depending on your location and the shipping method selected.",
    },
    {
      question: "How Much time will be taken to deliver the product?",
      answer:
        "The time taken to deliver your product depends on your location and the shipping method chosen. Typically, orders are delivered within [X to Y] business days from the date of dispatch.",
    },
    {
      question: "How can I track my order?",
      answer:
        "Once your order is dispatched, you will receive a shipping confirmation email with tracking information. You can use this tracking number to track the status of your delivery online through our website or the courier's website. If you have any questions or need assistance with tracking your order, please contact our customer service team.",
    },
  ];

  const handleShowAnswer = (index) => {
    setSelectedQuestion(index === selectedQuestion ? null : index);
  };

  return (
    <div className="faq">
      <h1>FAQ</h1>
      <div className="faq-interface">
        {FaqArray.map((faq, index) => (
          <div key={index}>
            <div className="question-section" onClick={() => handleShowAnswer(index)}>
              <h3>{faq.question}</h3>
              <span>{selectedQuestion === index ? "−" : "+"}</span>
            </div>
            <div className={`answer-section ${selectedQuestion === index ? "open" : "closed"}`}>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

