import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation } from 'react-router-dom';
import './NavTop.css';

const NavTop = () => {
    const Logo = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/jawd_transparent.png?updatedAt=1723459507629?tr=f-auto,q_auto"
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [expandedSections, setExpandedSections] = useState({
        topwears: false,
        bottomwears: false,
        accessories: false,
        combos: false,
        themes: false,
    });

    const toggleSection = useCallback((section) => {
        setExpandedSections((prevSections) => ({
            topwears: section === 'topwears' ? !prevSections.topwears : false,
            bottomwears: section === 'bottomwears' ? !prevSections.bottomwears : false,
            accessories: section === 'accessories' ? !prevSections.accessories : false,
            combos: section === 'combos' ? !prevSections.combos : false,
            themes: section === 'themes' ? !prevSections.themes : false,
        }));
    }, []);

    const [selectedOption, setSelectedOption] = useState('Men');
    let menuRef = useRef();
    let overlayRef = useRef();

    const toggleShoppingBag = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        setSelectedOption(option);
    };

    const handler = useCallback((e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && overlayRef.current && !overlayRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        };
    }, [handler]);

    return (
        <div className='navTop'>
            {location.pathname === "/" && (<>
                <div className='ship-container'>
                    <div className="free-ship-navTop">
                        <p style={{ color: "#000", textAlign: "center" }}>Choose.Avail.Purchase.Repeat.Enjoy FREE Shipping for any order.</p>
                    </div>
                    <div className="try-offer-navTop">
                        <p style={{ color: "#fff" }}>Try JAWD24 To Get Flat 5% OFF On Any Deal.</p>
                    </div>
                </div>
            </>)}
            <div className='navTop-menu'>
                <Link to="/">
                    <div className='navTop-logo'>
                        <img src={Logo} alt="Logo" width="80" height="26"/>
                    </div>
                </Link>
                <div className='icons'>
                    <div onClick={toggleShoppingBag}>
                        <RxHamburgerMenu size={20} style={{ marginRight: '6px', cursor: 'pointer' }} />
                    </div>
                </div>
            </div>
            {isOpen && <div className='overlay' ref={overlayRef} onClick={() => setIsOpen(false)} />}
            <div className={`shoppingBagSection ${isOpen ? 'open' : 'closed'}`} ref={menuRef}>
                <div className='jawd-intro-section'>
                </div>
                <div className='men-women-toggle-holder'>
                    <div className='men-women-toggle'>
                        <div className={`men-women-option ${selectedOption === 'Men' ? 'selected' : ''}`} onClick={() => selectOption('Men')}>Men</div>
                        <div className={`men-women-option ${selectedOption === 'Women' ? 'selected' : ''}`} onClick={() => selectOption('Women')}>
                            <p style={{ color: "#000" }}>Women</p>
                            <span>coming soon</span>
                        </div>
                    </div>
                </div>

                <div className='jawd-menu-section-holder'>
                    {selectedOption === 'Men' ? (
                        <div className='jawd-menu-section'>
                            <div onClick={() => toggleSection('topwears')} className='jawd-menu-section-submenu'>
                                <h4>Topwears</h4>
                                <p>{expandedSections.topwears ? <FaChevronUp className={`chevron-icon ${expandedSections.topwears ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.topwears ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/men/tshirt_solid_halfsleeve/solid" onClick={() => setIsOpen(false)}><p>Solid Tshirt</p></Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/all" onClick={() => setIsOpen(false)}>
                                    <p>Printed Tshirt</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/casual_college_outfit/all" onClick={() => setIsOpen(false)}>
                                    <p>College Outfit Combo</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('bottomwears')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Bottomwears</h4>
                                <p className='top-margin-submenu'>{expandedSections.bottomwears ? <FaChevronUp className={`chevron-icon ${expandedSections.bottomwears ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.bottomwears ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/men/joggers/all" onClick={() => setIsOpen(false)}>
                                    <p>Joggers</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/chinos/all" onClick={() => setIsOpen(false)}>
                                    <p>Chinos</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/cargo/all" onClick={() => setIsOpen(false)}>
                                    <p>Cargos</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('accessories')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Accessories</h4>
                                <p className='top-margin-submenu'>{expandedSections.accessories ? <FaChevronUp className={`chevron-icon ${expandedSections.accessories ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.accessories ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/men/printed_cap/solid" onClick={() => setIsOpen(false)}>
                                    <p>Solid Caps</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/printed_cap/all" onClick={() => setIsOpen(false)}>
                                    <p>Printed Caps</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('combos')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Combos</h4>
                                <p className='top-margin-submenu'>{expandedSections.combos ? <FaChevronUp className={`chevron-icon ${expandedSections.combos ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.combos ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/men/combo/joggers" onClick={() => setIsOpen(false)}>
                                    <p>Joggers Combos</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/combo/chinos" onClick={() => setIsOpen(false)}>
                                    <p>Chinos Combos</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/casual_college_outfit/all" onClick={() => setIsOpen(false)}>
                                    <p>College Outfit Combo</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/pick_any_two/joggers" onClick={() => setIsOpen(false)}>
                                    <p>Pick Any Two - Joggers</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/pick_any_two/cargo" onClick={() => setIsOpen(false)}>
                                    <p>Pick Any Two - Cargos</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/pick_any_two/chinos" onClick={() => setIsOpen(false)}>
                                    <p>Pick Any Two - Chinos</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('themes')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Themes</h4>
                                <p className='top-margin-submenu'>{expandedSections.themes ? <FaChevronUp className={`chevron-icon ${expandedSections.themes ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.themes ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/all" onClick={() => setIsOpen(false)}>
                                    <p>All</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/Sporty" onClick={() => setIsOpen(false)}>
                                    <p>Sporty</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/city" onClick={() => setIsOpen(false)}>
                                    <p>City</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/filmy" onClick={() => setIsOpen(false)}>
                                    <p>Filmy</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/iconic_fiction" onClick={() => setIsOpen(false)}>
                                    <p>Iconic Fiction</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/ranodm" onClick={() => setIsOpen(false)}>
                                    <p>Random</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/Voyager" onClick={() => setIsOpen(false)}>
                                    <p>Voyager</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/calligraphy" onClick={() => setIsOpen(false)}>
                                    <p>Calligraphy</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/iconic_character" onClick={() => setIsOpen(false)}>
                                    <p>Iconic Character</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/japanese_caligraphy" onClick={() => setIsOpen(false)}>
                                    <p>Japanese Calligraphy</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/motivational" onClick={() => setIsOpen(false)}>
                                    <p>Motivational</p>
                                </Link>
                                <Link className='prod-link' to="/products/men/tshirt_regular_halfsleeve/rider" onClick={() => setIsOpen(false)}>
                                    <p>Rider</p>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className='jawd-menu-section'>
                            {/* <div onClick={() => toggleSection('topwears')} className='jawd-menu-section-submenu'>
                                <h4>Topwears</h4>
                                <p>{expandedSections.topwears ? <FaChevronUp className={`chevron-icon ${expandedSections.topwears ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.topwears ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/women/tshirt_regular_halfsleeve/solid" onClick={() => setIsOpen(false)}>
                                    <p>Solid Tshirt</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/tshirt_regular_halfsleeve/printed" onClick={() => setIsOpen(false)}>
                                    <p>Printed Tshirt</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/casual_college_outfit/all" onClick={() => setIsOpen(false)}>
                                    <p>College Outfit Combo</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('bottomwears')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Bottomwears</h4>
                                <p className='top-margin-submenu'>{expandedSections.bottomwears ? <FaChevronUp className={`chevron-icon ${expandedSections.bottomwears ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.bottomwears ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/women/joggers/all" onClick={() => setIsOpen(false)}>
                                    <p>Joggers</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/chinos/all" onClick={() => setIsOpen(false)}>
                                    <p>Chinos</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/cargo/all" onClick={() => setIsOpen(false)}>
                                    <p>Cargos</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('accessories')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Accessories</h4>
                                <p className='top-margin-submenu'>{expandedSections.accessories ? <FaChevronUp className={`chevron-icon ${expandedSections.accessories ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.accessories ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/women/caps/solid" onClick={() => setIsOpen(false)}>
                                    <p>Solid Caps</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/caps/all" onClick={() => setIsOpen(false)}>
                                    <p>Printed Caps</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('combos')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Combos</h4>
                                <p className='top-margin-submenu'>{expandedSections.combos ? <FaChevronUp className={`chevron-icon ${expandedSections.combos ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.combos ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/women/combo/joggers" onClick={() => setIsOpen(false)}>
                                    <p>Joggers Combos</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/combo/chinos" onClick={() => setIsOpen(false)}>
                                    <p>Chinos Combos</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/casual_college_outfit/all" onClick={() => setIsOpen(false)}>
                                    <p>College Outfit Combo</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/pick_any_two/joggers" onClick={() => setIsOpen(false)}>
                                    <p>Pick Any Two - Joggers</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/pick_any_two/chinos" onClick={() => setIsOpen(false)}>
                                    <p>Pick Any Two - Chinos</p>
                                </Link>
                            </div>

                            <div onClick={() => toggleSection('themes')} className='jawd-menu-section-submenu'>
                                <h4 className='top-margin-submenu'>Themes</h4>
                                <p className='top-margin-submenu'>{expandedSections.themes ? <FaChevronUp className={`chevron-icon ${expandedSections.themes ? 'rotate' : ''}`} /> : <FaChevronDown className="chevron-icon" />}</p>
                            </div>
                            <div className={`products ${expandedSections.themes ? 'expanded' : ''}`}>
                                <Link className='prod-link' to="/products/women/theme/nature_lover" onClick={() => setIsOpen(false)}>
                                    <p>Nature Lover</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/romantic" onClick={() => setIsOpen(false)}>
                                    <p>Romantic</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/filmy" onClick={() => setIsOpen(false)}>
                                    <p>Filmy</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/pet_lover" onClick={() => setIsOpen(false)}>
                                    <p>Pet Lover</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/voyager" onClick={() => setIsOpen(false)}>
                                    <p>Voyager</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/nerdo" onClick={() => setIsOpen(false)}>
                                    <p>Nerd</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/gowf" onClick={() => setIsOpen(false)}>
                                    <p>Trendy</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/sports" onClick={() => setIsOpen(false)}>
                                    <p>Sporty</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/techie" onClick={() => setIsOpen(false)}>
                                    <p>Techie or Science Lover</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/anime" onClick={() => setIsOpen(false)}>
                                    <p>Anime Lover</p>
                                </Link>
                                <Link className='prod-link' to="/products/women/theme/music" onClick={() => setIsOpen(false)}>
                                    <p>Music Lover</p>
                                </Link>
                            </div> */}
                            <p>Women products will be added soon</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NavTop;





