import react from 'react';
import './WhatsAppConct.css'
import { IoLogoWhatsapp } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

const WhatsAppConct = () => {
    const location = useLocation();
    const isProduct = location.pathname.includes('product')

    const phoneNumber = '917439092310';
    const message = encodeURIComponent('Hello, I need assistance!');

    const handleClick = () => {
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(whatsappLink, '_blank');
    };
    return (
        <>
            <div className={isProduct ? `whatsapp-button` : `whatsapp-button-product`} onClick={handleClick}>
                <IoLogoWhatsapp color='white' size={30} />
            </div>
        </>
    )
}

export default WhatsAppConct;