import React from 'react';

function TruncatedText({ text, maxLength = 36 }) {
  return (
    <div>
      {text.length > maxLength ? (
        <span>
          {text.substring(0, maxLength)}
          {'...'}
        </span>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
}

export default TruncatedText;
