import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './Success.css';
import { Link } from 'react-router-dom';

const Success = () => {
    // Retrieve checkoutData from sessionStorage
    const checkoutData = JSON.parse(sessionStorage.getItem('checkoutData'));

    // Access userEmail field from checkoutData
    const userEmail = checkoutData ? checkoutData.userEmail : '';
    return (
        <div className='success-page'>
            <div className="success-tick">
                <FaCheck />
            </div>
            <h1>Thanks Jawder!</h1>
            <p>Thank you for choosing JAWD. We have sent you a mail on <span>{userEmail}</span> for product confirmation with product details. Check the mail for any further information.</p>
            <Link to="/">
            <a>Go Back</a>
            </Link>
        </div>
    )
}

export default Success