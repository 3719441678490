import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PathTracker = () => {
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem('currentPath', location.pathname);
    }, [location]);

    return null; // This component doesn't render anything
}

export default PathTracker;
