import React, { useEffect } from 'react'
import Footer from '../../component/Footer/Footer'
import Navbar from '../../component/Navbar/Navbar'
import NavTop from '../../component/NavTop/NavTop'
import { Link } from 'react-router-dom'

const ShippingPolicy = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return (
        <>
            <Navbar />
            <NavTop />
            <div className='about-us-content-holder'>
                <div className='about-us-content'>
                    <div className='abt-hd-1'>
                        <h1>Shipping Policy</h1>
                    </div>
                    <div className='abt-psg-1'>
                        <p>Jawd always ensures the most hassle free & seamless shipping experience to its community.   We give extra care to make your order reach to you on time and in a sound state.<br></br><br></br>
                            Once you order from our portal providing all the necessary information and payment (if the order is prepaid) a confirmation mail will be sent to you in your registered e-mail ID. We take 1-2 working days (Excluding Sundays, National Holidays, Regional Holidays & festivals) to prepare and pack your order from our end. In this period if a customer wants to cancel the order, it can be done very easily. But once the order is dispatched from the warehouse, the order cannot be cancelled. Then the only possible way is return, refund or exchange based on the situation which is well explained in the <Link to="/exchange/policy">https://wearejawd.in/exchange/policy</Link><br></br><br></br>
                            Please be informed that Jawd has tied up with different third party logistics companies who will take care of the delivery after dispatch of the product from our warehouse. If you enter any PIN Code which is not deliverable by any of our partnered third party logistics company, then the order will be automatically cancelled and our support team will contact you to explain you the situation and help you to find the probable solution.<br></br><br></br>
                            We provide free shipping for prepaid orders, but for COD we may charge an amount depending on the PIN Code, order value etc. as different third party logistics partner charge differently according to distance and cash handling.<br></br><br></br>
                            We try our best to ensure the customer gets the order on or before time, but things are not always in our hand as we have to depend on the third party logistics company for the delivery. So if delay happens, most likely the third party logistics company will be responsible for this and we will not be responsible, although we will try our best to avoid such situation.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy