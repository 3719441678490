import React, { useState } from 'react'
import './PincodeAvl.css'
import { fetchPinCodeDetails } from '../../services/Services'
import { ClipLoader } from 'react-spinners';

const PincodeAvl = () => {
  const DeliveryUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/delivery%20(2).png?updatedAt=1724741359892?tr=f-auto,q_quto"
  const CODDeliveryUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cash-on-delivery%20(2).png?updatedAt=1724603997043?tr=f-auto,q_auto"
  const OnlineDeliveryUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/credit-card.png?updatedAt=1724603997186?tr=f-auto,q_auto"
  const [pinCode, setPinCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [delAv, setDelAv] = useState('N');
  const [preAv, setPreAv] = useState('N');
  const [codAv, setCodAv] = useState('N');

  const handleCheck = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await fetchPinCodeDetails(pinCode);
      console.log(response);
      setDelAv(response.delivery_codes[0].postal_code.pre_paid);
      setPreAv(response.delivery_codes[0].postal_code.pre_paid);
      setCodAv(response.delivery_codes[0].postal_code.cod);
      if (response.delivery_codes.length === 0) {
        setErrorMessage('Pincode is not valid.');
      }
    } catch (error) {
      setErrorMessage('Pincode is not valid.');
    } finally {
      setLoading(false);
      console.log('Pincode checked');
    }
  };

  return (
    <>
      <div className="pincode-avl">
        <div className="pincode-avl__inner">
          <h3>Check Delivery Availibility</h3>
          <div className='avl-search-holder'>
            <input type='text' placeholder='Enter pincode to check...' value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}></input>
            <button onClick={handleCheck} disabled={loading}>
              {loading ? <ClipLoader color="#fff" size={16} /> : 'Check'}
            </button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className='check-availibility'>
            {(delAv || "").includes("Y") && (
              <div className='avl-holder'>
                <div className='avl-image-holder'>
                  <img src={DeliveryUrl} alt="Delivery Available" />
                </div>
                <p>Delivery Available</p>
              </div>
            )}
            {(preAv || "").includes("Y") && (
              <div className='avl-holder'>
                <div className='avl-image-holder'>
                  <img src={OnlineDeliveryUrl} alt="Online Payment Available" />
                </div>
                <p>Online Payment Available</p>
              </div>
            )}
            {(codAv || "").includes("Y") && (
              <div className='avl-holder'>
                <div className='avl-image-holder'>
                  <img src={CODDeliveryUrl} alt="COD Available" />
                </div>
                <p>COD Available</p>
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  )
}

export default PincodeAvl