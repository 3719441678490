import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./SearchBar.css";

const SearchBar = ({ series, onTypeChange }) => {
  const Search = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/search.png?updatedAt=1723459509780?tr=f-auto,q_auto"
  const location = useLocation();
  const [selectedType, setSelectedType] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleTypeClick = (type) => {
    setSelectedType(type.toLowerCase());
    onTypeChange(type.toLowerCase());
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onTypeChange(value.toLowerCase());
  };

  const isMatchingPath = location.pathname === '/products/men/printed_cap' || location.pathname === '/products/men/tshirt_regular_halfsleeve/all';

  return (
    <div className="search-bar">
      <div className="search-bar-holder">
        {isMatchingPath && (
          <div className="items">
            {series.map((item, index) => (
              <div
                className={`item ${selectedType === item ? 'selected' : ''}`}
                key={index}
                onClick={() => handleTypeClick(item)}
              >
                <p className="item-name">{item}</p>
              </div>
            ))}
          </div>
        )}
        {/* <div className="search-bar-search">
          <img src={Search} alt="Search icon" />
          <input
            placeholder="Search products.."
            type="text"
            value={searchValue}
            onChange={handleInputChange}
          />
        </div> */}
      </div>
    </div>
  );
};

export default SearchBar;


