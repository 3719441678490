import React, {useState} from 'react'
import './PartnerSignUp.css'
import Navbar from '../../component/Navbar/Navbar';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { registerPartner } from '../../services/Services';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PartnerSignUp = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleGoogleLogin = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            setLoading(true);
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const body = {
                userName: user.displayName,
                gmail: user.email,
            };

            sessionStorage.setItem('affilliateGmail', user.email);
            const partnerDetails = await registerPartner(body);

            navigate("/affiliate/dashboard")

            console.log(partnerDetails.data);
        } catch (error) {
            console.error('Error during Google login:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='partner-signup'>
            {loading && (
                <div className="loader-container">
                    <FaSpinner className="loader-icon" />
                </div>
            )}
            <Navbar />
            <div className='partner-signup-holder'>
                <div className='partner-signup-content'>
                    <h2>Be An Affiliate Partner</h2>
                    <h4>Join Jawd's Affiliate Program and Earn 5% on Each Order</h4>
                    <p>Joining Jawd's affiliate program offers an exciting opportunity to earn extra income by promoting our innovative products. As a Jawd affiliate partner, you'll receive 5% of the revenue from each order made through your unique referral link. This program is designed to be simple and rewarding, providing you with a steady stream of passive income for every successful referral.</p>
                    <h4>Terms and Conditions</h4>
                    <ol>
                        <li>
                            <p><span>Eligibility:</span> To become an affiliate partner, you must be at least 18 years old and have a valid email address. Registration is free and open to individuals worldwide.</p>
                        </li>
                        <li>
                            <p><span>Commission Structure:</span> Affiliates earn a 5% commission on the total sale amount of each order made through their referral link. Commissions are tracked in real-time and are payable monthly.</p>
                        </li>
                        <li>
                            <p><span>Referral Link:</span> Each affiliate will receive a unique referral link to share with their audience. This link tracks all sales attributed to the affiliate, ensuring accurate commission payments.</p>
                        </li>
                        <li>
                            <p><span>Payment:</span> Commissions are paid out via PayPal or direct bank transfer. A minimum threshold of $50 must be reached before payments are issued.</p>
                        </li>
                        <li>
                            <p><span>Promotion Guidelines:</span> Affiliates must promote Jawd products ethically and responsibly. Misleading advertisements or spammy practices are strictly prohibited and may result in termination of the affiliate agreement.</p>
                        </li>
                        <li>
                            <p><span>Termination:</span> Jawd reserves the right to terminate the affiliate agreement at any time for violations of the terms and conditions or any activity deemed harmful to the brand.</p>
                        </li>
                    </ol>
                    <p>
                        By joining Jawd's affiliate program, you can turn your influence into income while promoting products you believe in. Start earning today and grow with Jawd!</p>
                    <button onClick={handleGoogleLogin}>Join/Continue</button>
                </div>
            </div>
        </div>
    )
}

export default PartnerSignUp