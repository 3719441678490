import React, { useState } from "react";
import "./Information.css";
import FloatingLabelInput from "../../component/TransitionInput/TransitionInput";
import { AddDetails } from "../../services/Services";
import { auth } from "../../config/Firebase-config";

const Information = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // Handle the case where user is not logged in
        return;
      }

      const data = {
        uid_firebase: user.uid,
        email: email,
        phone: phone,
        address: address,
      };
      console.log(data);
      // Pass the data to the AddDetails function
      const result = await AddDetails(data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="information">
      <div className="information-holder">
        <h3>Why We Need Your Information?</h3>
        <p>
          When you will order any product from us, we will use your address as
          your default address (which your can change) and we will contact you
          through WhatsApp and email for your delivery progress or any other
          support.<br></br> Please give your active WhatsApp number.
        </p>
        <div className="input-box">
          <FloatingLabelInput
            onChange={setEmail}
            label="Enter Your Email Address"
          />
          <FloatingLabelInput
            onChange={setPhone}
            label="Enter Your Phone Number"
          />
          <FloatingLabelInput
            onChange={setAddress}
            label="Enter Your Address"
          />
          <button className="submit-info" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Information;
