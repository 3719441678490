import React, { useEffect } from 'react'
import './About.css'
import Navbar from '../../component/Navbar/Navbar'
import NavTop from '../../component/NavTop/NavTop'
import Footer from '../../component/Footer/Footer'
import { Helmet } from 'react-helmet'

const About = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return (
        <>
            <Navbar />
            <NavTop />
            <Helmet>
                <title>Buy trendy men's and women's dress online at low price - Jawd</title>
                <meta name='description' content='Online shopping like never before! Get the latest trends ruling the charts in India With the most fashionable range of apparels, accessories, and more. Buy trendy men and women Wear Online jawd will be your favourite of all online shopping sites' />
                <meta name='keywords' content="Best Shopping store,Best Online Shopping Site, Buy Online dress,Trendy men's and women's dress online , Trendy women's dress online" />
            </Helmet>
            <div className='about-us-content-holder'>
                <div className='about-us-content'>
                    <div className='abt-hd-1'>
                        <h1>About Us</h1>
                    </div>
                    <div className='abt-psg-1'>
                        <p>Three young minds once found a gap in the fashion segment in India. There is plenty of brands which cater to the quirky segment, casual segment, streetwear or formal segment and all, but the space between streetwear & formalwear is not that much explored. There is plenty of people who prefer to wear a unique combination according to their sense of fashion and unique personality. Those young minds aim to bridge the gap providing the fashion to those unique minds who wears to radiate their personality. <br></br>
                            Introducing JAWD – RADIATE YOUR PERSONA. JAWD is the one and only of its kind fusionwear brand of India, empowering peeps to radiate his/her true persona through the fashion. Founded in 2024, Jawd is in a mission to provide the freedom to its community to choose the fashion which highlights their true personality through it.
                        </p>
                        <h4>Meet Our Founding Team</h4>
                        <p>The dreamers behind the concept are Tirthankar Chakrabarti, Founder & Chief Executive Officer, Subhankar Chakrabarti, co-founder & Chief Technology Officer who is behind the seamless experience we try to provide to the Jawder’s through our website, and last but not the least Dhruba Ghosh, co-founder & Chief Operating Officer, who is behind all the back office operations and quality control, which ensures the best experience to the Jawders. </p>

                        <h4>Legal Information</h4>
                        <p><b>Legal Name:</b> Jawd Lifestyles LLP</p>
                        <p><b>Reg. Address:</b> 194/1 B.M. Saha Road, Uttarpara, Kolkata 712233, West Bengal</p>
                        <p><b>LLPIN:</b> ACI-2078</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About