import React, { useState, useEffect } from "react";
import "./Cart.css";
import Navbar from "../../component/Navbar/Navbar";
import { GetCartedItems } from "../../services/Services";
import { GetEventCoupons, getCODMail } from "../../services/Services";
import TopProductMobile from "../../component/TopProductMobile/TopProductMobile";
import { handleOnlinePayment } from "../../services/Services";
import { nanoid } from "nanoid";
import NavTop from "../../component/NavTop/NavTop";
import { IoMdClose } from 'react-icons/io';
import { AiOutlineLoading } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet"

const Cart = () => {
  const VisaCard = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/credit-card.png?updatedAt=1724603997186?tr=f-auto,q_auto"
  const UPI_icon = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/upi_icon.png?updatedAt=1723459510687?tr=f-auto,q_auto"
  const Net_Banking = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/online-banking.png?updatedAt=1724603997176?updatedAt=1723459507623?tr=f-auto,q_auto"
  const COD = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cash-on-delivery%20(2).png?updatedAt=1724603997043?tr=f-auto,q_auto"
  const Wallet = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/wallet.png?updatedAt=1724603997269?tr=f-auto,q_auto"
  const EmptyCart = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/empty_cart.png?updatedAt=1723459507643?tr=f-auto,q_auto"
  // just a comment for testing purpose
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTrackingPrice, setTotalTrackingPrice] = useState(0);
  const [totalInitialPrice, setTotalInitialPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [eventDiscountArr, setEventDiscountArr] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [couponApplied,setCouponApplied] = useState("");
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [isMailSent, setIsMailSent] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [step, setStep] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalCumulativePrice, setTotalCumulativePrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);

  const [formData, setFormData] = useState({
    userName: "",
    userAddress: "",
    Area: "",
    Landmark: "",
    contactNumber: "",
    userEmail: "",
    city: "",
    PinCode: "",
    state: "",
  });
  const [saveAddress, setSaveAddress] = useState(false);

  const {
    name,
    address,
    locality,
    landmark,
    contactNumber,
    email,
    city,
    pin,
    state,
  } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    const savedAddress = JSON.parse(localStorage.getItem("savedAddress"));
    if (savedAddress) {
      setFormData(savedAddress);
    }
  }, []);
  // const [referralApplied,setRef]

  useEffect(() => {
    const fetchCartItems = async () => {
      setIsItemsLoading(true);
      try {
        const productIdsArray = JSON.parse(localStorage.getItem("cartItems")) || [];
        const dataArr = { productIds: productIdsArray };

        const res = await GetCartedItems(dataArr);
        const itemsWithQuantity = res.map(item => ({ ...item, quantity: item.quantity || 1 }));
        setCartItems(itemsWithQuantity);
      } catch (error) {
        throw error;
      } finally {
        setIsItemsLoading(false);
      }
    };

    fetchCartItems();
  }, []);
  useEffect(() => {
    try {
      GetEventCoupons()
        .then((res) => {
          setEventDiscountArr(res);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    const calculateTotals = () => {
      const quantity = cartItems.reduce((total, item) => total + (parseInt(item.quantity) || 0), 0);
      const price = cartItems.reduce((total, item) => total + (parseFloat(item.discountedPrice) || 0) * (parseInt(item.quantity) || 0), 0);
      const initialTotalPrice = cartItems.reduce((total, item) => total + (parseFloat(item.originalPrice || 0)) * (parseInt(item.quantity) || 0), 0);
      setTotalInitialPrice(initialTotalPrice);

      setTotalQuantity(quantity);
      setTotalPrice(price);
      setTotalTrackingPrice(price);
    };
    if (Array.isArray(cartItems) && cartItems.length >= 0) {
      calculateTotals();
    }
  }, [cartItems]);

  const handleDecreaseQuantity = (index) => {
    setCartItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? { ...item, quantity: Math.max(0, item.quantity - 1) }
          : item
      )
    );
  };

  const handleIncreaseQuantity = (index) => {
    setCartItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleRemoveItem = (index) => {
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });

    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    items.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(items));
  };

  const handlePageNext = (e) => {
    if (saveAddress) {
      localStorage.setItem("savedAddress", JSON.stringify(formData));
    }
    if (step < 2) {
      setStep(step + 1);
    }
    if (step == 0) {
      e.preventDefault();
      const isAnyFieldEmpty = Object.values(formData).some(value => value === "");
      if (isAnyFieldEmpty) {
        toast.error("Please fill all the details correctly", { autoClose: 4000 });
        setStep(0);
      } else {
        setStep(step + 1);
      }
    }
    const referralAmount = parseInt(parseInt(totalInitialPrice) * (5 / 100));
    const referralID = sessionStorage.getItem("referralId");
    const orderedProducts = JSON.parse(localStorage.getItem("cartItems")).map(item => {
      const productId = item.split("_")[0];
      // Extracting productId
      const quantity = cartItems.find(cartItem => cartItem.id === productId).quantity;
      return { id: item, quantity: quantity };
    });
    const dataToStore = {
      userName: formData.userName,
      userAddress: formData.userAddress,
      Area: formData.Area,
      Landmark: formData.Landmark,
      contactNumber: formData.contactNumber,
      userEmail: formData.userEmail,
      city: formData.city,
      PinCode: formData.PinCode,
      state: formData.state,
      orderedProducts,
      ProductName: cartItems.map(item => item.productName),
      category: cartItems.map(item => item.category),
      totalPrice: totalCumulativePrice,
      selectedColor: "#000",
      selectedSize: cartItems.map(item => item.selectedSize),
      couponApplied: couponApplied ? couponApplied : "NA",
      referralApplied: referralCode ? referralCode : "NA",
      referralID: referralID ? referralID : "NA",
      AffiliateAmount: referralID ? referralAmount : 0,
      isPaid: isPaid,
      isDelivered: false
    };
    sessionStorage.setItem("checkoutData", JSON.stringify(dataToStore));

  }
  const handleCouponClick = (coupon) => {
    setSelectedCoupons((prevSelectedCoupons) => {
      let newSelectedCoupons;
      const discountValue = parseInt((totalTrackingPrice * coupon.percentageOff) / 100);
      if (prevSelectedCoupons.includes(coupon)) {
        newSelectedCoupons = prevSelectedCoupons.filter(c => c !== coupon);
        setTotalPrice(totalPrice + discountValue); // Add the discount value back
        setCouponApplied("NA");
      } else {
        newSelectedCoupons = [...prevSelectedCoupons, coupon];
        setTotalPrice(prevPrice => prevPrice - discountValue); // Subtract the discount value
        setCouponApplied(coupon.couponName);
      }
      return newSelectedCoupons;
    });
  };

  const handleCODClick = async () => {
    try {
      setIsPaid(false);

      // Wait for isPaid state to be updated before proceeding
      await new Promise(resolve => setTimeout(resolve, 0));

      // Retrieve checkoutData from sessionStorage
      const checkoutData = JSON.parse(sessionStorage.getItem("checkoutData"));

      // Call getCODMail function with checkoutData and handle response
      setIsMailSent(true);
      const response = await getCODMail(checkoutData);

      // Retrieve existing orderIds from localStorage or initialize as an empty array
      const orderIds = JSON.parse(localStorage.getItem("orderIds")) || [];

      // Append the new order_id to the orderIds array
      orderIds.push(response.data.order_id);

      // Save the updated orderIds array back to localStorage
      localStorage.setItem("orderIds", JSON.stringify(orderIds));

      // Navigate to the success page
      navigate("/success/payment");
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsMailSent(false);
      localStorage.removeItem("cartItems");
    }
  };

  const handleOnlineCheckout = async (method) => {
    try {
      // Set isPaid to true since this is an online payment
      setIsPaid(true);

      // Wait for isPaid state to be updated before proceeding
      await new Promise(resolve => setTimeout(resolve, 0));

      // Retrieve checkoutData from sessionStorage
      const checkoutData = JSON.parse(sessionStorage.getItem("checkoutData"));
      console.log("checkoutData:", checkoutData);

      // Generate a receipt ID
      const recp_id = nanoid(6);

      // Handle the online payment
      const result = await handleOnlinePayment(totalCumulativePrice, recp_id, formData.userName, formData.userEmail, formData.contactNumber, formData.userAddress,method);

      // Proceed with the same steps as handleCODClick if the payment is successful
      console.log("Payment was successful!");

      // Call getCODMail function with checkoutData and handle response
      setIsMailSent(true);
      const response = await getCODMail(checkoutData);
      console.log("Response from getCODMail:", response.data.order_id);

      // Retrieve existing orderIds from localStorage or initialize as an empty array
      const orderIds = JSON.parse(localStorage.getItem("orderIds")) || [];

      // Append the new order_id to the orderIds array
      orderIds.push(response.data.order_id);

      // Save the updated orderIds array back to localStorage
      localStorage.setItem("orderIds", JSON.stringify(orderIds));

      // Navigate to the success page
      navigate("/success/payment");

    } catch (error) {
      console.log("Payment failed");
      console.error("Error processing online payment:", error);
    } finally {
      setIsMailSent(false);
    }
  };

  useEffect(() => {
    const taxCalculation = parseInt(totalPrice * (5 / 100));
    setTax(taxCalculation);
  }, [totalInitialPrice, totalPrice]);
  useEffect(() => {
    setTotalCumulativePrice(totalPrice + tax);
  }, [totalPrice, tax])
  return (
    <div className="cart-container">
      <NavTop />
      <Navbar />
      <div className='top-product-mobile'>
        <TopProductMobile />
      </div>
      <Helmet>
        <title>Online shopping for men`s and women`s clothing and accessories - Jawd</title>
        <meta name="description" content="Grab our lifestyle fashion offer. We are Deals on Women s Clothing, Men s Clothing with Free Alterations Exchange. Easy Returns,COD Exchanges available.Order Now." />
        <meta name="keywords" content="Online shopping for men`s clothing and accessories , Online shopping for women`s clothing and accessories , Best shopping for men`s clothing and accessories ,Best shopping for women`s clothing and accessories" />
      </Helmet>
      <div className="cart">
        {isMailSent && (
          <div className="loading-payment">
            <AiOutlineLoading className="spinner" />
            <h3>Verifying your response...</h3>
          </div>
        )}
        {isModalOpen && (
          <div className="modal-container-holder">
            <div className="modal-container">
              <div className="cross-field">
                <IoMdClose onClick={() => setIsModalOpen(false)} size={20} style={{ marginRight: "2%", cursor: "pointer" }} />
              </div>
              <div className="scrollable-content">
                {step === 0 && (
                  <div className="address-form-cart">
                    <div className="address-form-holder">
                      <div className="address-form">
                        <h3 style={{ color: "#000", fontWeight: "500", textDecoration: "underline" }}>Fill Your Address</h3>
                        <div className="full-width-input">
                          <p>Name: *</p>
                          <input
                            type="text"
                            name="userName"
                            value={formData.userName}
                            onChange={handleChange}
                            placeholder="Enter Your Full Name..."
                          ></input>
                        </div>
                        <div className="full-width-input">
                          <p>Address: *</p>
                          <input
                            type="text"
                            name="userAddress"
                            value={formData.userAddress}
                            onChange={handleChange}
                            placeholder="Enter Delivery Address..."
                          ></input>
                        </div>
                        <div className="half-width-input">
                          <div className="half-width-input-holder">
                            <p>Locality/Area: *</p>
                            <input
                              type="text"
                              name="Area"
                              value={formData.Area}
                              onChange={handleChange}
                              placeholder="Eg. Greater Kolkata Area..."
                            ></input>
                          </div>
                          <div className="half-width-input-holder">
                            <p>Landmark: *</p>
                            <input
                              type="text"
                              name="Landmark"
                              value={formData.Landmark}
                              onChange={handleChange}
                              placeholder="Eg. South City Mall..."
                            ></input>
                          </div>
                        </div>
                        <div className="half-width-input">
                          <div className="half-width-input-holder">
                            <p>Contact Number: *</p>
                            <input
                              type="text"
                              name="contactNumber"
                              value={formData.contactNumber}
                              onChange={handleChange}
                              placeholder="Enter Contact Number..."
                            ></input>
                          </div>
                          <div className="half-width-input-holder">
                            <p>Email: *</p>
                            <input
                              type="text"
                              name="userEmail"
                              value={formData.userEmail}
                              onChange={handleChange}
                              placeholder="Enter Email Address..."
                            ></input>
                          </div>
                        </div>
                        <div className="half-width-input">
                          <div className="half-width-input-holder">
                            <p>City: *</p>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                              placeholder="Eg. Kolkata..."
                            ></input>
                          </div>
                          <div className="half-width-input-holder">
                            <p>PIN: *</p>
                            <input
                              type="text"
                              name="PinCode"
                              value={formData.PinCode}
                              onChange={handleChange}
                              placeholder="Enter PIN Code..."
                            ></input>
                          </div>
                        </div>
                        <div className="full-width-input">
                          <p>State: *</p>
                          <input
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            placeholder="Enter State Name..."
                          ></input>
                        </div>
                        <div className="checkbox-holder">
                          <div className="checkbox">
                            <input type="checkbox"
                              checked={saveAddress}
                              onChange={(e) => setSaveAddress(e.target.checked)} />
                            <label>Save my address</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 1 && (
                  <>
                    <div className="coupon-sec-holder">
                      <h3 style={{ color: "#000", fontWeight: "500", textDecoration: "underline" }}>Available Coupons</h3>
                      <div className="coupon-sec">
                        {eventDiscountArr && eventDiscountArr.map((coupon, index) => (
                          <div className={`coupon ${selectedCoupons.includes(coupon) ? 'selected' : ''}`}
                            key={index}
                            onClick={() => handleCouponClick(coupon)}>
                            <div className="coupon-content">
                              <h3>{coupon.couponName}</h3>
                              <p>{coupon.percentageOff}% OFF</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <p className="avail">Click to avail*</p>
                      <p>Enter Referral Code:</p>
                      <div className="referral-holder">
                        <input type="text"></input>
                        <button>Apply</button>
                      </div>
                      <div className="cart-checkout-content">
                        <div className="cart-checkout-holder">
                          <p>Total Quantity</p>
                          <p>{totalQuantity}</p>
                        </div>
                        <div className="cart-checkout-holder">
                          <p>Initial Price</p>
                          <p>Rs. {totalInitialPrice.toFixed(0)}</p>
                        </div>
                        <div className="cart-checkout-holder">
                          <p>Discounted Price</p>
                          <p>Rs. {totalPrice.toFixed(0)}</p>
                        </div>
                        <div className="cart-checkout-holder">
                          <p>CGST & SGST</p>
                          <p>Rs.{tax}</p>
                        </div>
                        <div className="cart-checkout-holder">
                          <p>Shipping Charge</p>
                          <p>FREE</p>
                        </div>
                        <div className="cart-checkout-holder">
                          <p className="to-be-paid">To be Paid</p>
                          <p className="total-price">Rs.{parseInt(totalCumulativePrice)}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {step == 2 && (
                  <div className="coupon-sec-holder">
                    <div className="payment-opts-cart">
                      <h3 style={{ color: "#000", fontWeight: "500", textDecoration: "underline" }}>Payment Options</h3>
                      <div className="opts-holder opts" onClick={() => handleOnlineCheckout('upi')}>
                        <div className="payment-opt">
                          <div className="icon-holder">
                            <img src={UPI_icon} />
                          </div>
                          <h4>UPI <span>2% OFF</span></h4>
                        </div>
                        <IoIosArrowForward style={{ color: "#8c8c8c" }} />
                      </div>
                      <div className="opts-holder opts" onClick={() => handleOnlineCheckout('netbanking')}>
                        <div className="payment-opt">
                          <div className="icon-holder">
                            <img src={Net_Banking} />
                          </div>
                          <h4>Net Banking <span>2% OFF</span></h4>
                        </div>
                        <IoIosArrowForward style={{ color: "#8c8c8c" }} />
                      </div>
                      <div className="opts-holder opts" onClick={() => handleOnlineCheckout('card')}>
                        <div className="payment-opt">
                          <div className="icon-holder">
                            <img src={VisaCard} />
                          </div>
                          <h4>Credit / Debit Card</h4>
                        </div>
                        <IoIosArrowForward style={{ color: "#8c8c8c" }} />
                      </div>
                      <div className="opts-holder opts" onClick={() => handleOnlineCheckout('wallet')}>
                        <div className="payment-opt">
                          <div className="icon-holder">
                            <img src={Wallet} />
                          </div>
                          <h4>wallet <span>2% OFF</span></h4>
                        </div>
                        <IoIosArrowForward style={{ color: "#8c8c8c" }} />
                      </div>
                      <div className="opts-holder opts" onClick={handleCODClick}>
                        <div className="payment-opt">
                          <div className="icon-holder">
                            <img src={COD} />
                          </div>
                          <h4>Cash On Delivery</h4>
                        </div>
                        <IoIosArrowForward style={{ color: "#8c8c8c" }} />
                      </div>
                    </div>
                    {/* {!isMailSent && ( */}
                    {/* //  )} */}
                  </div>
                )}

              </div>
              <div className="pgrs-btn">
                <button onClick={() => step > 0 && setStep(step - 1)}>Prev</button>
                <button onClick={handlePageNext}>Next</button>
              </div>
            </div>
          </div>
        )}
        <div className="cart-holder">
          <div className="cart-and-suggestion">
            <div className="cart-section">
              {isItemsLoading ? (
                <div className="product-list-skull-holder">
                  {Array.from({ length: 5 }, (_, index) => (
                    <div className="product-list-skull skull-animation" key={index}>
                      <div className="pic-holder-animate"></div>
                      <div className="content-animate">
                        <div className="content-1-animate"></div>
                        <div className="content-2-animate"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {cartItems.length !== 0 ? (
                    <>
                      <h1 className="cart-heading">Shopping Cart ({totalQuantity} items)</h1>
                      {cartItems.map((item, index) => (
                        <div className="carted-product" key={`${item.productName}-${index}`}>
                          <div className="carted-product-holder">
                            <div className="img-and-title">
                              <div className="cart-img">
                                <img src={item.colorImageUrl.replace('/upload/', '/upload/f_auto,q_auto/')} alt={item.productName} />
                              </div>
                              <div className="cart-details">
                                <div className="cart-title">
                                  <h2>
                                    {item.productName} ("{item.selectedSize}" Size)
                                  </h2>
                                  <div className="quantity-section">
                                    <div className="quantity">
                                      <button
                                        onClick={() => handleDecreaseQuantity(index)}
                                        className="inc-btn"
                                        style={{ outline: "none", border: "none" }}
                                        disabled={item.quantity < 2}
                                      >
                                        −
                                      </button>
                                      <div className="quantity-field">
                                        {item.quantity}
                                      </div>
                                      <div
                                        onClick={() => handleIncreaseQuantity(index)}
                                        className="inc-btn"
                                      >
                                        +
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => handleRemoveItem(index)}
                                      className="remove-item"
                                    >
                                      Remove
                                    </div>
                                  </div>
                                </div>
                                <h3 className="price-cart">₹{(item.discountedPrice) * (item.quantity)}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="center-cart">
                      <img src={EmptyCart} alt="Empty Cart" />
                      <div className="is-mobile">
                        <p>Hmm...your cart looks empty, click the shopping bag icon at the top to start shopping.</p>
                      </div>
                      <div className="not-mobile">
                        <p>Hmm...your cart looks empty, hover on the Men, Women option to start shopping.</p>
                      </div>
                    </div>
                  )}
                </>
              )}


              <div className="checkout-sticky">
                <div className="checkout-details">
                  <div className="quant-holder-mobile">
                    <h4>Checkout From Here</h4>
                    <div className="quant">
                      <p>Total Products: </p>
                      <p>{totalQuantity}</p>
                    </div>
                    <div className="quant">
                      <p>Total Price: </p>
                      <p>₹{totalInitialPrice.toFixed(0)}</p>
                    </div>
                    <div className="quant estimated-total">
                      <p>Discounted Price:</p>
                      <p>₹{totalPrice.toFixed(0)}</p>
                    </div>
                  </div>
                </div>
                <button onClick={() => setIsModalOpen(true)} className="checkout-sticky-btn" disabled={cartItems.length == 0}>Checkout</button>
              </div>
            </div>
          </div>
          <div className="pay-and-details">
            <div className="checkout-details">
              {/* <div className="line-checkout"></div> */}
              <div className="quant-holder">
                <h4>Checkout From Here</h4>
                <div className="quant">
                  <p>Total Products: </p>
                  <p>{totalQuantity}</p>
                </div>
                <div className="quant">
                  <p>Total Price: </p>
                  <p>₹{totalInitialPrice.toFixed(0)}</p>
                </div>
                <div className="quant estimated-total">
                  <p>Discounted Price:</p>
                  <p>₹{totalPrice.toFixed(0)}</p>
                </div>
                <button style={{ fontSize: "16px", height: "8vh", cursor: "pointer", marginTop: "-8px" }} onClick={() => setIsModalOpen(true)} disabled={cartItems.length == 0} className="checkout-btn">
                  Checkout
                </button>
                <p style={{ fontSize: "13px", color: "#000", fontWeight: "500", marginTop: "10px" }}>Click Checkout to Apply offers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer style={{ zIndex: "10000000" }} />
    </div>
  );
};

export default Cart;