import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate, Link } from 'react-router-dom'
import './Hero.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Hero = () => {
  const JoggersImg = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/hero_sec_img.png?updatedAt=1723356679139?tr=f-auto,q_auto";
  const CapsImg = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/hero_sec_img_2.png?updatedAt=1723356679773?tr=f-auto,q_auto";
  const ChinosImg = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/hero_sec_img_3.png?updatedAt=1723356681239?tr=f-auto,q_auto";
  const MobileBannerChinos = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cargo_torned_post.png?updatedAt=1724072252819?tr=f-auto,q_auto"
  return (
    <div className='hero'>

      <div className='hero-sc-xl'>
        <Carousel minimumTouchDrag={30} responsive={responsive} swipeable={true} renderDotsOutside={true} infinite={true} showDots={true}
          autoPlay={true} autoPlaySpeed={4000} keyBoardControl={true} ssr={true}>
          <Link to="/products/all/joggers/all">
            <img alt='joggers-banner' src={JoggersImg} />
          </Link>
          <Link to="/products/all/printed_cap/all">
            <img alt='caps-banner' src={CapsImg} />
          </Link>
          <Link to="/products/men/chinos/all">
            <img alt='chinos-banner' src={ChinosImg} />
          </Link>
        </Carousel>
      </div>
      <div className='hero-sc-sl'>
        <Link to="/products/men/cargo/all">
          <img alt='mobile-banner-chinos' src={MobileBannerChinos} width="425" height="556" />
        </Link>
      </div>
    </div>
  )
}

export default Hero