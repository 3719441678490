import React, { useEffect, useState } from 'react';
import './Exchange.css';
import Navbar from '../../component/Navbar/Navbar';
import { getOrderedItems, postCancelProduct, postExchangeProduct } from '../../services/Services';
import TopProductMobile from '../../component/TopProductMobile/TopProductMobile';
import { AiOutlineLoading } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavTop from '../../component/NavTop/NavTop';

const Exchange = () => {
    const EmptyList = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/empty_list.jpg?updatedAt=1723459507415?tr=f-auto,q_auto"
    const [checkedItems, setCheckedItems] = useState([]);
    const [orderedItems, setOrderedItems] = useState([]);
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [showEmptyList, setShowEmptyList] = useState(false);
    const [activeTab, setActiveTab] = useState('receivedProducts');
    const [RecievedProduct, setRecievedProducts] = useState([]);
    const [isRecievedEmpty, setIsRecievedEmpty] = useState(false);
    const [onGoingOrders, setOngoingOrders] = useState([]);
    const [isOngoingEmpty, setIsOngoingEmpty] = useState(false);

    function handleCheckboxChange(productId) {
        setCheckedItems(prevState => ({
            ...prevState,
            [productId]: !prevState[productId]
        }));
    }

    const handleRequestExchange = async () => {
        // Get selected order IDs based on checked items
        const selectedOrderIds = RecievedProduct
            .filter(product => checkedItems[product._id]) // Filter based on product ID in checkedItems
            .map(product => product._id);

        if (selectedOrderIds.length === 0) {
            toast.error("Please select at least one product to proceed");
        } else {
            setIsSending(true);

            const requestData = {
                orderIds: selectedOrderIds,
                userName: userName,
                contactNumber: contactNumber,
                userEmail: userEmail
            };
            try {
                const res = await postExchangeProduct(requestData);
                console.log(res);
                toast.success('Request sent successfully! Check Mail.', { autoClose: 5000 });

                // Deselect all products
                setCheckedItems({});

                // Store exchanged product IDs in local storage
                const storedExchangedIds = JSON.parse(localStorage.getItem('exchangedOrderIds')) || [];
                const newExchangedIds = [...storedExchangedIds, ...selectedOrderIds];
                localStorage.setItem('exchangedOrderIds', JSON.stringify(newExchangedIds));

                // Update RecievedProduct state to remove the exchanged products
                setRecievedProducts(prevRecievedProducts => prevRecievedProducts.filter(item => !selectedOrderIds.includes(item._id)));

                // Remove selected order IDs from local storage
                const storedOrderIds = JSON.parse(localStorage.getItem('orderIds')) || [];
                const remainingOrderIds = storedOrderIds.filter(id => !selectedOrderIds.includes(id));
                localStorage.setItem('orderIds', JSON.stringify(remainingOrderIds));

                if (remainingOrderIds.length === 0) {
                    setIsRecievedEmpty(true);
                }
            } catch (err) {
                console.error(err);
                toast.error('Failed to send request. Please try again.', { autoClose: 5000 });
                setIsRecievedEmpty(true);
            } finally {
                setIsSending(false);
            }
        }
    };

    const handleRequestCancel = async () => {
        // Get selected order IDs based on checked items
        const selectedOrderIds = orderedItems
            .filter(product => checkedItems[product._id]) // Filter based on product ID in checkedItems
            .map(product => product._id);

        if (selectedOrderIds.length === 0) {
            toast.error("Please select at least one product to proceed");
        } else {
            setIsSending(true);

            const requestData = {
                orderIds: selectedOrderIds,
                userName: userName,
                contactNumber: contactNumber,
                userEmail: userEmail
            };
            try {
                const res = await postCancelProduct(requestData);
                console.log(res);
                toast.success('Request sent successfully! Check Mail.', { autoClose: 5000 });

                // Deselect all products
                setCheckedItems({});

                const storedCanceledIds = JSON.parse(localStorage.getItem('canceledOrderIds')) || [];
                const newCanceledIds = [...storedCanceledIds, ...selectedOrderIds];
                localStorage.setItem('canceledOrderIds', JSON.stringify(newCanceledIds));

                // Update ordered items state to remove the canceled products
                setOngoingOrders(prevOrderedItems => prevOrderedItems.filter(item => !selectedOrderIds.includes(item._id)));

                // Remove selected order IDs from local storage
                const storedOrderIds = JSON.parse(localStorage.getItem('orderIds')) || [];
                const remainingOrderIds = storedOrderIds.filter(id => !selectedOrderIds.includes(id));
                localStorage.setItem('orderIds', JSON.stringify(remainingOrderIds));

                if (remainingOrderIds.length === 0) {
                    setIsRecievedEmpty(true);
                }
            } catch (err) {
                console.error(err);
                toast.error('Failed to send request. Please try again.', { autoClose: 5000 });
                setIsRecievedEmpty(true);
            } finally {
                setIsSending(false);
            }
        }
    };


    useEffect(() => {
        const orderIds = JSON.parse(localStorage.getItem('orderIds')) || [];
        setIsLoading(true);
        if (orderIds.length > 0) {
            getOrderedItems(orderIds).then((res) => {
                console.log("Ordered data details: ", res.data.data);
                const orderedData = res.data.data;
                setOrderedItems(orderedData);
                setData(orderedData)

                if (orderedData.length > 0) {
                    setUserEmail(orderedData[0].userEmail);
                    setUserName(orderedData[0].userName);
                    setContactNumber(orderedData[0].contactNumber);
                }
                const receivedProducts = orderedData.filter(item => item.isDelivered);
                setRecievedProducts(receivedProducts);
                setIsRecievedEmpty(receivedProducts.length === 0);
                const ongoingOrders = orderedData.filter(item => !item.isPlaced);
                setIsOngoingEmpty(ongoingOrders.length === 0);
                setOngoingOrders(ongoingOrders);
            }).catch((err) => {
                console.log(err);
                // if (err.response && err.response.status === 404) {
                    setShowEmptyList(true);
                // }
            }).finally(() => {
                setIsLoading(false);
                console.log("finally");
            });
        } else {
            setIsLoading(false);
            setShowEmptyList(true);
        }
    }, []);

    const renderContent = () => {
        const handleExpiredProductClick = () => {
            toast.error('Time to exchange product has expired', {
                autoClose: 3000,
            });
        };

        const isProductExpired = (createdAt) => {
            const currentDate = new Date();
            const productDate = new Date(createdAt);
            const timeDiff = currentDate - productDate;
            const dayDiff = timeDiff / (1000 * 3600 * 24);
            return dayDiff > 5;
        };

        if (activeTab === 'receivedProducts') {
            return (
                <>
                    {isRecievedEmpty && (
                        <div className='empty-list'>
                            <img src={EmptyList} alt="Empty List" />
                            <p>You haven't recieved any product yet, that's why the list is empty</p>
                        </div>
                    )}
                    {isLoading ? (
                        <div className='ordered-product-list'>
                            {Array.from({ length: 10 }, (_, index) => (
                                <div
                                    key={index}
                                    className={`ordered-product ${checkedItems[index] ? 'checked' : ''}`}
                                    style={{ backgroundColor: checkedItems[index] ? 'lightblue' : 'transparent' }}
                                    onClick={() => handleCheckboxChange(index)}
                                >
                                    <div className='check-product-animate skull-animation'></div>
                                    <div className='ordered-product-details'>
                                        <div className='orderd-product-img-loader skull-animation'></div>
                                        <div className='orderd-product-content'>
                                            <div className='h3-width skull-animation'></div>
                                            <div className='p-width skull-animation'></div>
                                        </div>
                                    </div>
                                    <div className='ordered-product-other-details'>
                                        <div className='date-width delv-width skull-animation'></div>
                                        <div className='qty-width delv-width skull-animation'></div>
                                        <div className='price-width delv-width skull-animation'></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='ordered-product-list'>
                            {RecievedProduct && RecievedProduct.map((product, index) => {
                                const isExpired = isProductExpired(product.createdAt);
                                const reversedIndex = onGoingOrders.length - 1 - index; // Adjusted index for original order
                                const isChecked = checkedItems[product._id] || false; // Use product ID to manage checked state
                                return (
                                    <div
                                        key={index}
                                        className={`ordered-product ${isChecked ? 'checked' : ''}`}
                                        style={{
                                            opacity: isExpired ? 0.5 : 1,
                                            pointerEvents: isExpired ? 'none' : 'auto',
                                        }}
                                        onClick={() => {
                                            if (isExpired) {
                                                handleExpiredProductClick();
                                            } else {
                                                handleCheckboxChange(product._id);
                                            }
                                        }}
                                    >
                                        <div className='check-product'>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => handleCheckboxChange(product._id)}
                                            />
                                        </div>
                                        <div className='ordered-product-details'>
                                            <div className='orderd-product-img'>
                                                <img src={product.orderedProduct.cardImgUrl} alt={product.orderedProduct.name} />
                                            </div>
                                            <div className='orderd-product-content'>
                                                <h3>{product.orderedProduct.name}</h3>
                                                <p>id: {product._id}</p>
                                            </div>
                                        </div>
                                        <div className='ordered-product-other-details'>
                                            <h3>{new Date(product.createdAt).toLocaleDateString()}</h3>
                                            <h3>QTY: {product.orderedProduct.quantity}</h3>
                                            <h3 className='ordered-product-price'>Rs. {product.totalPrice}</h3>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            );
        } else if (activeTab === 'ongoingOrders') {
            return (
                <>
                    {isOngoingEmpty && (
                        <div className='empty-list'>
                            <img src={EmptyList} alt="Empty List" />
                            <p>You haven't ordered anything yet, that's why the list is empty</p>
                        </div>
                    )}
                    {isLoading ? (
                        <div className='ordered-product-list'>
                            {Array.from({ length: 10 }, (_, index) => (
                                <div
                                    key={index}
                                    className={`ordered-product ${checkedItems[index] ? 'checked' : ''}`}
                                    style={{ backgroundColor: checkedItems[index] ? 'lightblue' : 'transparent' }}
                                    onClick={() => handleCheckboxChange(index)}
                                >
                                    <div className='check-product-animate skull-animation'></div>
                                    <div className='ordered-product-details'>
                                        <div className='orderd-product-img-loader skull-animation'></div>
                                        <div className='orderd-product-content'>
                                            <div className='h3-width skull-animation'></div>
                                            <div className='p-width skull-animation'></div>
                                        </div>
                                    </div>
                                    <div className='ordered-product-other-details'>
                                        <div className='date-width delv-width skull-animation'></div>
                                        <div className='qty-width delv-width skull-animation'></div>
                                        <div className='price-width delv-width skull-animation'></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='ordered-product-list'>
                            {onGoingOrders && onGoingOrders.slice().reverse().map((product, index) => {
                                const reversedIndex = onGoingOrders.length - 1 - index; // Adjusted index for original order
                                const isChecked = checkedItems[product._id] || false; // Use product ID to manage checked state

                                return (
                                    <div
                                        key={product._id}
                                        className={`ordered-product ${isChecked ? 'checked' : ''}`}
                                        onClick={() => handleCheckboxChange(product._id)}
                                    >
                                        <div className='check-product'>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => handleCheckboxChange(product._id)}
                                            />
                                        </div>
                                        <div className='ordered-product-details'>
                                            <div className='orderd-product-img'>
                                                <img src={product.orderedProduct.cardImgUrl} alt={product.orderedProduct.name} />
                                            </div>
                                            <div className='orderd-product-content'>
                                                <h3>{product.orderedProduct.name}</h3>
                                                <p>id: {product._id}</p>
                                            </div>
                                        </div>
                                        <div className='ordered-product-other-details'>
                                            <h3>{new Date(product.createdAt).toLocaleDateString()}</h3>
                                            <h3>QTY: {product.orderedProduct.quantity}</h3>
                                            <h3 className='ordered-product-price'>Rs. {product.totalPrice}</h3>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            );
        }
    };

    const isButtonDisabled = () => {
        if (activeTab === 'receivedProducts') {
            return RecievedProduct.length === 0;
        } else {
            return onGoingOrders.length === 0;
        }
    };
    return (
        <div className='exchange'>
            <Navbar />
            <NavTop />
            <div className='top-product-mobile'>
                <TopProductMobile />
            </div>
            {isSending && (
                <div className="loading-payment">
                    <AiOutlineLoading className="spinner" />
                    <h3>Processing your request...</h3>
                </div>
            )}
            <div className='exchange-section-holder'>
                <div className='request-btn'>
                    <button disabled={isButtonDisabled()} onClick={activeTab === 'receivedProducts' ? handleRequestExchange : handleRequestCancel}>{activeTab === 'receivedProducts' ? 'Request to Exchange' : 'Request to Cancel'}</button>
                </div>

                {/* New Toggle Section */}
                <div className='toggle-section'>
                    <div
                        className={`toggle-option ${activeTab === 'receivedProducts' ? 'active' : ''}`}
                        onClick={() => setActiveTab('receivedProducts')}
                    >
                        Received Products
                    </div>
                    <div
                        className={`toggle-option ${activeTab === 'ongoingOrders' ? 'active' : ''}`}
                        onClick={() => setActiveTab('ongoingOrders')}
                    >
                        Ongoing Orders
                    </div>
                </div>

                {/* Render Content Based on Selected Tab */}
                {/* <div className='content-section'> */}
                {renderContent()}
                {/* </div> */}
                <ToastContainer style={{ zIndex: "100000000" }} />
            </div>
        </div>
    );
}
export default Exchange;