import React, { useEffect, useState } from 'react'
import "./Dashboard.css"
import Navbar from '../../component/Navbar/Navbar'
import Footer from '../../component/Footer/Footer'
import { getPartnerID } from '../../services/Services'
import { Helmet } from "react-helmet"

const Dashboard = () => {
    const [referralID,setReferralId] = useState("hello.world");
    const [partnerId,setpartnerId] = useState("hello.world");
    const [userName,setUserName] = useState("hello.world");
    const [conversions,setConversions] = useState(0);
    const [totalConversions,setTotalConversions] = useState(0);
    const [totalEarnings,setTotalEarnings] = useState(0);
    const [withdrwaAmount,setWithdrawAmount] = useState(0);
    useEffect(()=>{
        const userGmail = sessionStorage.getItem('affilliateGmail');
        // getPartnerID
        console.log(userGmail);
        const data = {
            gmail: userGmail
        }
        getPartnerID(data).then((res)=>{
            console.log(res.data);
            setReferralId(res.data.affiliateDetails.referralID);
            setpartnerId(res.data.affiliateDetails._id);
            setUserName(res.data.affiliateDetails.userName);
            setConversions(res.data.filteredData.totalOrders);
            setTotalConversions(res.data.overallData.totalOrders);
            setTotalEarnings(res.data.overallData.totalAffiliateAmount);
            setWithdrawAmount(res.data.filteredData.totalAffiliateAmount);
        }).catch((err)=>{
            console.log(err);
        })
    },[])
  return (
    <div className='dashboard'>
        <Navbar />
        <Helmet>
            <title>Online Shopping Site for Western Wear Dresses - Jawd</title>
        </Helmet>
        <div className='dashboard-container'>
            <div className='dashboard-container-holder'>
                <h3>Hello, {userName}</h3>
                <div className='dashboard-content'>
                    <div className='dashboard-box'>
                        <p>Your Referral Id</p>
                        <h1 className='others'>{referralID}</h1>
                        <p>Copy link</p>
                    </div>
                    <div className='dashboard-box'>
                        <p>Total conversions (Last 15 days)</p>
                        <h1 className='numbers'>{conversions}</h1>
                    </div>
                    <div className='dashboard-box'>
                        <p>Total conversions (Overall)</p>
                        <h1 className='numbers'>{totalConversions}</h1>
                    </div>
                    <div className='dashboard-box'>
                        <p>Estimated total revenue</p>
                        <h1 className='numbers'>₹ {totalEarnings}</h1>
                    </div>
                    <div className='dashboard-box'>
                        <p>Total revenue to withdraw</p>
                        <h1 className='numbers'>₹ {withdrwaAmount}</h1>
                    </div>
                    <div className='dashboard-box'>
                        <p>Your Partner Id</p>
                        <h1 className='others'>{partnerId}</h1>
                        <p>Copy Partner Id</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Dashboard