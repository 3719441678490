import React, { useState } from 'react';
import './TransitionInput.css';

function FloatingLabelInput({ label, onChange }) {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <div className={`FloatingLabelInput ${value ? 'Focused' : ''}`}>
      <label className="InputLabel">{label}</label>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className="InputBox"
      />
    </div>
  );
}

export default FloatingLabelInput;
