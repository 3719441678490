import React, { useContext, useEffect, useState } from "react";
import "./Checkout.css";
import Navbar from "../../component/Navbar/Navbar";
import {
  GetCartedItems,
  getCODMail,
  handleOnlinePayment,
} from "../../services/Services";
import TopProductMobile from "../../component/TopProductMobile/TopProductMobile";
import { GetEventCoupons } from "../../services/Services";
import { FiX } from "react-icons/fi";
import { AiOutlineLoading } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import NavTop from "../../component/NavTop/NavTop";
import { ToastContainer, toast } from 'react-toastify';
import getColorName from "../../Utils/HexColorConversion.js/HexColor";
import { Helmet } from 'react-helmet'
import { nanoid } from "nanoid";

const Checkout = () => {
  const VisaCard = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/credit-card.png?updatedAt=1724603997186?tr=f-auto,q_auto"
  const UPI_icon = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/upi_icon.png?updatedAt=1723459510687?tr=f-auto,q_auto"
  const Net_Banking = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/online-banking.png?updatedAt=1724603997176?updatedAt=1723459507623?tr=f-auto,q_auto"
  const COD = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cash-on-delivery%20(2).png?updatedAt=1724603997043?tr=f-auto,q_auto"
  const Wallet = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/wallet.png?updatedAt=1724603997269?tr=f-auto,q_auto"
  const navigate = useNavigate();
  const [cardImage, setCardImage] = useState("");
  const [productName, setProductName] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [originalPrintingprice, setOriginPrintingPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(discountedPrice);
  const [discountedPrintingPrice, setDiscountedPrintedPrice] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [eventDiscountArr, setEventDiscountArr] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [tax, setTax] = useState(null);
  const [printedTax, setPrintedTax] = useState(null);
  const [total, setTotal] = useState(null);
  const [printedTotal, setPrintedTotal] = useState(null);
  const [usedCoupons, setUsedCoupons] = useState([]);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isReferralApplied, setIsReferralApplied] = useState(false);
  const [appliedReferralCode, setAppliedReferralCode] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [couponApplied,setCouponApplied] = useState("");
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
  const [category, setCategory] = useState("");
  const [isMailSent, setIsMailSent] = useState(false);
  const [confirmAddress, setConfirmAddress] = useState(false);
  const [isPaid, setIsPaid] = useState(true);
  const [formData, setFormData] = useState({
    userName: "",
    userAddress: "",
    Area: "",
    Landmark: "",
    contactNumber: "",
    userEmail: "",
    city: "",
    PinCode: "",
    state: "",
  });

  const requiredFields = [
    "userName",
    "userAddress",
    "Area",
    "Landmark",
    "contactNumber",
    "userEmail",
    "city",
    "PinCode",
    "state",
  ];
  const [saveAddress, setSaveAddress] = useState(false);

  const {
    name,
    address,
    locality,
    landmark,
    contactNumber,
    email,
    city,
    pin,
    state,
  } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  useEffect(() => {
    const savedAddress = JSON.parse(localStorage.getItem("savedAddress"));
    if (savedAddress) {
      setFormData(savedAddress);
    }
  }, []);
  useEffect(() => {
    try {
      const buyNowItem = localStorage.getItem("buyNowItem");
      const productIdsArray = buyNowItem ? [buyNowItem] : [];
      console.log(productIdsArray);
      const dataArr = {
        productIds: productIdsArray,
      };

      GetCartedItems(dataArr)
        .then((res) => {
          console.log("Item details: ", res);

          const transformedColorImageUrl = res[0].colorImageUrl
            .replace('/upload/', '/upload/f_auto,q_auto/');

          const originalPriceNum = parseFloat(res[0].originalPrice);
          const discountedPriceNum = parseFloat(res[0].discountedPrice);
          setCardImage(transformedColorImageUrl);
          setProductName(res[0].productName);
          setOriginalPrice(originalPriceNum);
          setDiscountedPrice(discountedPriceNum);
          setDisplayPrice(discountedPriceNum);
          setSelectedSize(res[0].selectedSize);
          setSelectedColor(res[0].selectedColor);
          setCategory(res[0].category);
        })
        .catch((err) => {
          console.log(err);
        });
      GetEventCoupons()
        .then((res) => {
          console.log(res);
          setEventDiscountArr(res);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    const tax = discountedPrice * (5 / 100);
    setTax(parseInt(tax));
    setTotal(parseInt((parseInt(discountedPrice) + parseInt(tax)) * quantity));
    console.log(
      "parseInt(discountedPrice) = ",
      parseInt(discountedPrice),
      "tax = ",
      tax,
      "(parseInt(discountedPrice) + tax) * quantity: ",
      (parseInt(discountedPrice) + tax) * quantity
    );
  }, [discountedPrice, quantity]);

  const currentDate = new Date();
  const deliveryDate = new Date(currentDate);
  deliveryDate.setDate(currentDate.getDate() + 7); // Add 7 days
  const endDate = new Date(deliveryDate);
  endDate.setDate(deliveryDate.getDate() + 3); // Add 3 more days to get a total of 7-10 days

  const formattedDeliveryDate = `${deliveryDate.toLocaleString("en-us", {
    month: "long",
  })} ${deliveryDate.getDate()}, ${deliveryDate.getFullYear()} - ${endDate.toLocaleString(
    "en-us",
    { month: "long" }
  )} ${endDate.getDate()}, ${endDate.getFullYear()}`;


  // Function to recalculate the discounted price based on selected coupons
  const recalculateDiscountedPrice = (price, coupons) => {
    let discountedPrice = price;
    coupons.forEach(coupon => {
      discountedPrice -= calculateDiscount(coupon.percentageOff, discountedPrice);
    });
    return discountedPrice;
  };

  const handleCouponClick = (coupon) => {
    setSelectedCoupons(prevCoupons => {
      const isAlreadySelected = prevCoupons.some(c => c.id === coupon.id);
      let updatedCoupons;

      if (isAlreadySelected) {
        // Remove coupon
        updatedCoupons = prevCoupons.filter(c => c.id !== coupon.id);
        setCouponApplied("NA");
      } else {
        // Add coupon
        updatedCoupons = [...prevCoupons, coupon];
        setCouponApplied(coupon.couponName)
      }

      // Recalculate the discounted price
      const newDiscountedPrice = recalculateDiscountedPrice(displayPrice, updatedCoupons);
      setDiscountedPrice(newDiscountedPrice);

      return updatedCoupons;
    });
  };


  // Ensure initial price setup
  useEffect(() => {
    setDiscountedPrice(recalculateDiscountedPrice(displayPrice, selectedCoupons));
  }, [selectedCoupons]);

  // const handleReferral = async () => {
  //   try {
  //     console.log(referralCode);
  //     checkReferral(referralCode)
  //       .then((res) => {
  //         console.log(res);
  //         setIsReferralApplied(res.isAvailable);
  //         setButtonClicked(true);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  useEffect(() => {
    if (
      buttonClicked &&
      isReferralApplied &&
      referralCode !== appliedReferralCode
    ) {
      console.log("Entered if statement...");
      if (quantity === 1) {
        setDiscountedPrice((prevPrice) => {
          const newPrice = prevPrice - parseInt(prevPrice * (5 / 100));
          return newPrice;
        });
      } else {
        setDiscountedPrintedPrice((prevPrice) => {
          const newPrice = prevPrice - parseInt(prevPrice * (5 / 100));
          return newPrice;
        });
      }
      setAppliedReferralCode(referralCode); // Set the applied referral code to prevent re-application
    }
    setButtonClicked(false);
  }, [
    buttonClicked,
    isReferralApplied,
    referralCode,
    appliedReferralCode,
    quantity,
  ]);

  const calculateDiscount = (percentageOff, price) => {
    return price * (percentageOff / 100);
  };

  useEffect(() => {
    const updateSessionStorage = () => {
      const referralAmount = parseInt(parseInt(originalPrice) * (5 / 100));
      const orderedProducts = [
        {
          id: localStorage.getItem("buyNowItem"),
          quantity,
        },
      ];
      const referralID = sessionStorage.getItem("referralId");

      const dataToStore = {
        userName: formData.userName,
        userAddress: formData.userAddress,
        Area: formData.Area,
        Landmark: formData.Landmark,
        contactNumber: formData.contactNumber,
        userEmail: formData.userEmail,
        city: formData.city,
        PinCode: formData.PinCode,
        state: formData.state,
        orderedProducts,
        ProductName: [productName],
        category: [category],
        totalPrice: total,
        selectedColor,
        selectedSize: [selectedSize],
        couponApplied: couponApplied ? couponApplied : "NA",
        referralApplied: referralCode ? referralCode : "NA",
        referralID: referralID ? referralID : "NA",
        AffiliateAmount: referralID ? referralAmount : 0,
        isPaid: isPaid,
        isDelivered: false
      };

      sessionStorage.setItem("checkoutData", JSON.stringify(dataToStore));
    };

    updateSessionStorage();
  }, [
    originalPrintingprice,
    originalPrice,
    total,
    productName,
    category,
    selectedColor,
    selectedSize,
    referralCode,
    formData,
    quantity,
    isPaid, // Ensure sessionStorage is updated when isPaid changes
  ]);

  const handleConfirmAddress = () => {
    const isAddressFilled = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );
    if (isAddressFilled) {
      setIsAddressConfirmed(true);
      setConfirmAddress(true);

      const referralAmount = parseInt(parseInt(originalPrice) * (5 / 100));
      console.log("Referrer will get: ", referralAmount);
      const orderedProducts = [
        {
          id: localStorage.getItem("buyNowItem"),
          quantity,
        },
      ];
      const referralID = sessionStorage.getItem("referralId");

      const dataToStore = {
        userName: formData.userName,
        userAddress: formData.userAddress,
        Area: formData.Area,
        Landmark: formData.Landmark,
        contactNumber: formData.contactNumber,
        userEmail: formData.userEmail,
        city: formData.city,
        PinCode: formData.PinCode,
        state: formData.state,
        orderedProducts,
        ProductName: [productName],
        category: [category],
        totalPrice: total,
        selectedColor,
        selectedSize: [selectedSize],
        couponApplied: couponApplied ? couponApplied : "NA",
        referralApplied: referralCode ? referralCode : "NA",
        referralID: referralID ? referralID : "NA",
        AffiliateAmount: (referralAmount && referralID) ? referralAmount : 0,
        isPaid: isPaid,
        isDelivered: false
      };

      sessionStorage.setItem("checkoutData", JSON.stringify(dataToStore));

      if (saveAddress) {
        localStorage.setItem("savedAddress", JSON.stringify(formData));
      }
    } else {
      toast.error('Please Fill All The Fields With Correct Details', { autoClose: 5000 })
    }
  };
  useEffect(() => {
    setIsAddressConfirmed(false);
  }, [originalPrice, originalPrintingprice, formData]);

  const handleCODClick = async () => {
    try {
      setIsPaid(false);

      // Wait for isPaid state to be updated before proceeding
      await new Promise(resolve => setTimeout(resolve, 0));

      // Retrieve checkoutData from sessionStorage
      const checkoutData = JSON.parse(sessionStorage.getItem("checkoutData"));
      console.log("checkoutData:", checkoutData);

      // Call getCODMail function with checkoutData and handle response
      setIsMailSent(true);
      const response = await getCODMail(checkoutData);
      console.log("Response from getCODMail:", response.data.order_id);

      // Retrieve existing orderIds from localStorage or initialize as an empty array
      const orderIds = JSON.parse(localStorage.getItem("orderIds")) || [];

      // Append the new order_id to the orderIds array
      orderIds.push(response.data.order_id);

      // Save the updated orderIds array back to localStorage
      localStorage.setItem("orderIds", JSON.stringify(orderIds));

      // Navigate to the success page
      navigate("/success/payment");
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsMailSent(false);
    }
  };

  const handleOnlineCheckout = async (method) => {
    try {
      // Set isPaid to true since this is an online payment
      setIsPaid(true);

      // Wait for isPaid state to be updated before proceeding
      await new Promise(resolve => setTimeout(resolve, 0));

      // Retrieve checkoutData from sessionStorage
      const checkoutData = JSON.parse(sessionStorage.getItem("checkoutData"));
      console.log("checkoutData:", checkoutData);

      // Generate a receipt ID
      const recp_id = nanoid(6);

      // Handle the online payment
      const result = await handleOnlinePayment(total, recp_id, formData.userName, formData.userEmail, formData.contactNumber, formData.userAddress, method);

      // Proceed with the same steps as handleCODClick if the payment is successful
      console.log("Payment was successful!");

      // Call getCODMail function with checkoutData and handle response
      setIsMailSent(true);
      const response = await getCODMail(checkoutData);
      console.log("Response from getCODMail:", response.data.order_id);

      // Retrieve existing orderIds from localStorage or initialize as an empty array
      const orderIds = JSON.parse(localStorage.getItem("orderIds")) || [];

      // Append the new order_id to the orderIds array
      orderIds.push(response.data.order_id);

      // Save the updated orderIds array back to localStorage
      localStorage.setItem("orderIds", JSON.stringify(orderIds));

      // Navigate to the success page
      navigate("/success/payment");

    } catch (error) {
      console.log("Payment failed");
      console.error("Error processing online payment:", error);
    } finally {
      setIsMailSent(false);
    }
  };

  return (
    <>
      <div className="checkout">
        <Navbar />
        <NavTop />
        <div className='top-product-mobile'>
          <TopProductMobile />
        </div>
        <Helmet>
          <title>Online Shopping for Women's, Men's Fashion & Lifestyle - Jawd</title>
          <meta name="description" content="Buy from Best Online Shopping Sites for Combos, Jawd and enjoy great discounts on the latest arrivals" />
          <meta name="keywords" content="Shopping site for Women's, Men's Fashion & Lifestyle , Best Shopping site for Women's, Men's Fashion & Lifestyle ,Men's Fashion & Lifestyle ,Women's Fashion & Lifestyle" />
        </Helmet>
        <div className="checkout-holder">
          <div className="checkout-page">
            <div className="prod-and-address">
              <div className="prod-for-checkout">
                <div className="prod-checkout-img">
                  <img src={cardImage} />
                </div>
                <div className="prod-checkout-content">
                  <h3>{productName}</h3>
                  <p>Selected size: {selectedSize}</p>
                  <p className="color-selected">
                    Selected color: {getColorName(selectedColor)}
                  </p>
                  <div className="quantity-section">
                    <p>Quantity:</p>
                    {/* <input
                      type="number"
                      min="1"
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                    /> */}
                    <div className="quantity">
                      <div className="inc-btn" onClick={() => quantity != 1 ? setQuantity(quantity - 1) : 1}>-</div>
                      <div className="quantity-field">{quantity}</div>
                      <div className="inc-btn" onClick={() => {
                        setQuantity(quantity + 1)
                        console.log("Original Price is: ", originalPrice);
                        console.log("Quantity is: ", quantity);
                        console.log("Total pric after multiplying is: ", originalPrice * quantity)
                      }}>+</div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                Estimated Delivery Date:{" "}
                <span className="delivery-date">{formattedDeliveryDate}</span>
              </h4>
              <div className="adress-form-holder">
                <div className="address-form">
                  <div className="full-width-input">
                    <p>Name: *</p>
                    <input
                      type="text"
                      name="userName"
                      value={formData.userName}
                      onChange={handleChange}
                      placeholder="Enter Your Full Name..."
                    ></input>
                  </div>
                  <div className="full-width-input">
                    <p>Address: *</p>
                    <input
                      type="text"
                      name="userAddress"
                      value={formData.userAddress}
                      onChange={handleChange}
                      placeholder="Enter Delivery Address..."
                    ></input>
                  </div>
                  <div className="half-width-input">
                    <div className="half-width-input-holder">
                      <p>Locality/Area: *</p>
                      <input
                        type="text"
                        name="Area"
                        value={formData.Area}
                        onChange={handleChange}
                        placeholder="Eg. Greater Kolkata Area..."
                      ></input>
                    </div>
                    <div className="half-width-input-holder">
                      <p>Landmark: *</p>
                      <input
                        type="text"
                        name="Landmark"
                        value={formData.Landmark}
                        onChange={handleChange}
                        placeholder="Eg. South City Mall..."
                      ></input>
                    </div>
                  </div>
                  <div className="half-width-input">
                    <div className="half-width-input-holder">
                      <p>Contact Number: *</p>
                      <input
                        type="text"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        placeholder="Enter Contact Number..."
                      ></input>
                    </div>
                    <div className="half-width-input-holder">
                      <p>Email: *</p>
                      <input
                        type="text"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleChange}
                        placeholder="Enter Email Address..."
                      ></input>
                    </div>
                  </div>
                  <div className="half-width-input">
                    <div className="half-width-input-holder">
                      <p>City: *</p>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="Eg. Kolkata..."
                      ></input>
                    </div>
                    <div className="half-width-input-holder">
                      <p>PIN: *</p>
                      <input
                        type="text"
                        name="PinCode"
                        value={formData.PinCode}
                        onChange={handleChange}
                        placeholder="Enter PIN Code..."
                      ></input>
                    </div>
                  </div>
                  <div className="full-width-input">
                    <p>State: *</p>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      placeholder="Enter State Name..."
                    ></input>
                  </div>
                  <div className="checkbox-holder">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        checked={saveAddress}
                        onChange={(e) => setSaveAddress(e.target.checked)}
                      />
                      <label>Save my address</label>
                    </div>
                  </div>
                  <button onClick={handleConfirmAddress}>Confirm Address</button>
                </div>
              </div>
            </div>
            <div className="single-prodcheckout-details">
              <div className="checkout-details-holder">
                <h3>Available Coupons:</h3>
                <div className="event-coupon-holder">
                  {eventDiscountArr &&
                    eventDiscountArr.map((coupon) => (
                      <>
                        <div
                          className={`event-coupon ${selectedCoupons.includes(coupon) ? "selected" : ""
                            }`}
                          key={coupon.id}
                          onClick={() => handleCouponClick(coupon)}
                        >
                          <div className="event-coupon-content">
                            <h3>{coupon.couponName}</h3>
                            <p>{coupon.percentageOff}% OFF</p>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <p>*Click To Avail</p>
                <h3 className="custom-coupon">Enter Referral Code:</h3>
                <div className="referral-input-box">
                  <input
                    onChange={(e) => setReferralCode(e.target.value)}
                    type="text"
                  ></input>
                  <button className="apply-btn">
                    Apply
                  </button>
                </div>
                <div className="checkout-price-details">
                  <div className="checkout-price">
                    <h4>Initial Price</h4>
                    {/* {quantity == 1 ? (
                      <h4>Rs. {originalPrice}</h4>
                    ) : (
                      <h4>Rs. {originalPrintingprice}</h4>
                    )} */}
                    <h4>{originalPrice * quantity}</h4>
                  </div>
                  <div className="checkout-price cp">
                    <h4>Discounted Price</h4>
                    {/* {quantity == 1 ? (
                      <h4>Rs. {discountedPrice}</h4>
                    ) : (
                      <h4>Rs. {parseInt(discountedPrintingPrice)}</h4>
                    )} */}
                    <h4>{discountedPrice * quantity}</h4>
                  </div>
                  <div className="checkout-price cp">
                    <h4>CGST & SGST</h4>
                    <h4>Rs. {tax * quantity}</h4>
                  </div>
                  <div className="checkout-price cp">
                    <h4>Shipping Charge</h4>
                    <h4>FREE</h4>
                  </div>
                  <div className="checkout-price cp">
                    <h4>Total Payable</h4>
                    <h4>Rs. {total}</h4>
                  </div>
                </div>
                <button
                  disabled={!isAddressConfirmed}
                  onClick={() => setIsPaymentOpen(true)}
                >
                  {isAddressConfirmed
                    ? "Check payment options"
                    : "Confirm address first"}
                </button>
              </div>
            </div>
            {confirmAddress && (
              <>
                <div className="single-prodcheckout-details-mobile-overlay"></div>
                <div className="single-prodcheckout-details-mobile">
                  <div className="checkout-details-holder">
                    <div className="cross-bar">
                      <div
                        onClick={() => setConfirmAddress(false)}
                        className="cross-btn"
                      >
                        <FiX />
                      </div>
                    </div>
                    <h3>Available Coupons:</h3>
                    <div className="event-coupon-holder">
                      {eventDiscountArr &&
                        eventDiscountArr.map((coupon) => (
                          <>
                            <div
                              className={`event-coupon ${selectedCoupons.includes(coupon) ? "selected" : ""
                                }`}
                              key={coupon.id}
                              onClick={() => handleCouponClick(coupon)}
                            >
                              <div className="event-coupon-content">
                                <h3>{coupon.couponName}</h3>
                                <p>{coupon.percentageOff}% OFF</p>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                    <p>*Click To Avail</p>
                    <h3 className="custom-coupon">Enter Referral Code:</h3>
                    <div className="referral-input-box">
                      <input
                        onChange={(e) => setReferralCode(e.target.value)}
                        type="text"
                      ></input>
                      <button className="apply-btn">
                        Apply
                      </button>
                    </div>
                    <div className="checkout-price-details">
                      <div className="checkout-price">
                        <h4>Initial Price</h4>
                        {/* {quantity == 1 ? (
                          <h4>Rs. {originalPrice}</h4>
                        ) : (
                          <h4>Rs. {originalPrintingprice}</h4>
                        )} */}
                        <h4>{originalPrice * quantity}</h4>
                      </div>
                      <div className="checkout-price cp">
                        <h4>Discounted Price</h4>
                        {/* {quantity == 1 ? (
                          <h4>Rs. {discountedPrice * quantity}</h4>
                        ) : (
                          <h4>Rs. {parseInt(discountedPrintingPrice)}</h4>
                        )} */}
                        <h4>{discountedPrice * quantity}</h4>
                      </div>
                      <div className="checkout-price cp">
                        <h4>CGST & SGST</h4>
                        <h4>Rs. {tax * quantity}</h4>
                      </div>
                      <div className="checkout-price cp">
                        <h4>Shipping Charge</h4>
                        <h4>FREE</h4>
                      </div>
                      <div className="checkout-price cp">
                        <h4 className="total-payable">TOTAL PAYABLE</h4>
                        <h4 className="total-payable">₹ {total}</h4>
                      </div>
                    </div>
                    <button
                      disabled={!isAddressConfirmed}
                      onClick={() => setIsPaymentOpen(true)}
                    >
                      {isAddressConfirmed
                        ? "Check payment options"
                        : "Confirm address first"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isPaymentOpen && (
          <div className="payement-opts-holder">
            <div className="payment-opts">
              <div className="opts-holder cross-opt">
                <div
                  onClick={() => setIsPaymentOpen(false)}
                  className="cross-btn"
                >
                  <FiX />
                </div>
              </div>
              <div className="opts-holder opts" onClick={() => handleOnlineCheckout('upi')}>
                <div className="payment-opt">
                  <div className="icon-holder">
                    <img src={UPI_icon} />
                  </div>
                  <h4>UPI <span>2% OFF</span></h4>
                </div>
                <IoIosArrowForward style={{ color: "#8c8c8c" }} />
              </div>
              <div className="opts-holder opts" onClick={() => handleOnlineCheckout('netbanking')}>
                <div className="payment-opt">
                  <div className="icon-holder">
                    <img src={Net_Banking} />
                  </div>
                  <h4>Net Banking <span>2% OFF</span></h4>
                </div>
                <IoIosArrowForward style={{ color: "#8c8c8c" }} />
              </div>
              <div className="opts-holder opts" onClick={() => handleOnlineCheckout('card')}>
                <div className="payment-opt">
                  <div className="icon-holder">
                    <img src={VisaCard} />
                  </div>
                  <h4>Credit / Debit Card</h4>
                </div>
                <IoIosArrowForward style={{ color: "#8c8c8c" }} />
              </div>
              <div className="opts-holder opts" onClick={() => handleOnlineCheckout('wallet')}>
                <div className="payment-opt">
                  <div className="icon-holder">
                    <img src={Wallet} />
                  </div>
                    <h4>wallet <span>2% OFF</span></h4>
                </div>
                <IoIosArrowForward style={{ color: "#8c8c8c" }} />
              </div>
              <div className="opts-holder opts" onClick={handleCODClick}>
                <div className="payment-opt">
                  <div className="icon-holder">
                    <img src={COD} />
                  </div>
                  <h4>Cash On Delivery</h4>
                </div>
                <IoIosArrowForward style={{ color: "#8c8c8c" }} />
              </div>
            </div>
            {/* {!isMailSent && ( */}
            {/* //  )} */}
          </div>
        )}
        {isMailSent && (
          <div className="loading-payment">
            <AiOutlineLoading className="spinner" />
            <h3>Verifying your response...</h3>
          </div>
        )}
        <ToastContainer style={{ zIndex: "100000000" }} />
      </div>
    </>
  );
};

export default Checkout;