import React from "react";
import { Link } from "react-router-dom";
import './TopProducts.css';

const TopProducts = () => {
  const BannerOffUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/banner_3.png?updatedAt=1723356673597?tr=f-auto,q_auto"
  const BannerTopUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/banner_2.png?updatedAt=1724260130391?tr=f-auto,q_auto"

  const TshirtNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/printed_tshirt_mockup.png?updatedAt=1724083019816?tr=f-auto,q_auto"
  const CargoNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cargo_mockup.png?updatedAt=1724083019967?tr=f-auto,q_auto"
  const CapsNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/caps_mockup.png?updatedAt=1724084787980?tr=f-auto,q_auto"
  const ChinosNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/chinos_mockup.png?updatedAt=1724083018880?tr=f-auto,q_auto"
  const CombosNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/combo_mockup.png?updatedAt=1724087192392?tr=f-auto,q_auto"
  const JoggersNewUrl = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/joggers_mockup.png?updatedAt=1724087852697?tr=f-auto,q_auto"



  return (
    <>
      <div className="top-products">
        <div className="top-products-holder">
          <div className="product-list">
              <img style={{width: "100%", marginBottom: "15px"}} src={BannerTopUrl}  width="1024" height="267"/>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/tshirt_regular_halfsleeve/all">
                <div className="product-list-product-img">
                  <img alt="printed-tshirt" src={TshirtNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>printed tshirt</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/cargo/all">
                <div className="product-list-product-img">
                  <img alt="cargo-img" src={CargoNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>6 pocket cargo</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/printed_cap/all">
                <div className="product-list-product-img">
                  <img alt="printed-cap-img" src={CapsNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>printed cap</p> */}
              </Link>
            </div>
          </div>
          <div className="banner">
            <img alt="banner-off-sale" src={BannerOffUrl} loading="lazy" />
          </div>
          <div className="product-list">
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/combo/all">
                <div className="product-list-product-img">
                  <img alt="bottomwear-img" src={CombosNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>bottomwear combos</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/joggers/all">
                <div className="product-list-product-img">
                  <img alt="joggers-img" src={JoggersNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>joggers</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/chinos/all">
                <div className="product-list-product-img">
                  <img alt="chinos-img" src={ChinosNewUrl} loading="lazy" />
                </div>
                {/* <p style={{ textAlign: "center" }}>chinos</p> */}
              </Link>
            </div>
          </div>
        </div>


        <div className="top-products-mobile-holder">
          <div className="product-list">
            <img style={{width: "100%", marginBottom: "15px", marginTop: "15px"}} src={BannerTopUrl}  width="425" height="111"/>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/tshirt_regular_halfsleeve/all">
                <div className="product-list-product-img">
                  <img alt="printed-tshirt-img" src={TshirtNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>printed tshirt</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/cargo/all">
                <div className="product-list-product-img">
                  <img alt="cargo-img" src={CargoNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>6 pocket cargo</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/printed_cap/all">
                <div className="product-list-product-img">
                  <img alt="printed-cap-img" src={CapsNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>printed cap</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/chinos/all">
                <div className="product-list-product-img">
                  <img alt="bottomwear-img" src={ChinosNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>bottomwear combos</p> */}
              </Link>
            </div>
          </div>

          <div className="product-list under-banner">
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/men/combo/all">
                <div className="product-list-product-img">
                  <img alt="joggers-img" src={CombosNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>joggers</p> */}
              </Link>
            </div>
            <div className="product-list-product">
              <Link style={{ textDecoration: "none" }} to="/products/all/joggers/all">
                <div className="product-list-product-img">
                  <img alt="chinos-img" src={JoggersNewUrl} loading="lazy" width="198" height="259"/>
                </div>
                {/* <p style={{ textAlign: "center" }}>chinos</p> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-holder">
        <div className="banner1">
          <div className="banner">
            <img alt="banner-off-sale" src={BannerOffUrl} loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopProducts;